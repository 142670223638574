import axios from 'axios'
import { apiaddr } from '../../config'

function getInstrace (baseURL = apiaddr, headers = { 'Content-Type': 'application/json;charset=UTF-8' }) {
  // 自定义axios实例
  const instance = axios.create()
  // 相对URL
  instance.defaults.baseURL = baseURL
  // 请求超时时间
  instance.defaults.timeout = 20000
  // 请求头部
  instance.defaults.headers = headers
  return instance
}
export default getInstrace
