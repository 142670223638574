<template>
  <div class="header-con">
    <div class="m-header" v-if="isMobile">
      <div class="m-top">
        <img src="@/assets/imgs/logo.png" class="logo" @click="goTop">
        <img src="@/assets/imgs/m-menu.svg" class="m-menu" @click="showMenu" v-if="isMenu">
        <img src="@/assets/imgs/m-close.svg" class="m-menu" v-else @click="isMenu=true">
      </div>
      <div class="header-height"></div>
      <div class="nav-list" v-show="!isMenu">
        <div class="item" ><a href="/home#About" @click="isMenu=true">ABOUT</a></div>
        <div class="item" ><a href="/home#Platform" @click="isMenu=true">PLATFORM</a></div>
        <div class="item" ><a href="/partnership" @click="isMenu=true">PARTNERSHIP</a></div>
        <div class="item" ><a href="/home#Pipline" @click="isMenu=true">PIPELINE</a></div>
        <div class="item"  ><a href="/home#News" @click="isMenu=true">NEWS</a></div>
        <div class="item"  ><a href="/careers" @click="isMenu=true">CAREERS</a></div>
        <div class="item" ><a href="/home#Contact" @click="isMenu=true">Contact</a></div>
      </div>
    </div>
    <div class="pc-header" v-else>
      <div class="header-top">
        <img src="@/assets/imgs/logo.png" class="logo"  @click="goTop">
        <div class="nav-list">
          <div class="item" ><a href="/home#About" @click="isMenu=true">ABOUT</a></div>
          <div class="item" ><a href="/home#Platform" @click="isMenu=true">PLATFORM</a></div>
          <div class="item" ><a href="/partnership" @click="isMenu=true">PARTNERSHIP</a></div>
          <div class="item" ><a href="/home#Pipline" @click="isMenu=true">PIPELINE</a></div>
          <div class="item"  ><a href="/home#News" @click="isMenu=true">NEWS</a></div>
          <div class="item"  ><a href="/careers" @click="isMenu=true">CAREERS</a></div>
        </div>
        <div class="contact"><a href="/home#Contact" @click="isMenu=true">Contact</a></div>
      </div>
      <div class="header-height"></div>
    </div>
  </div>

</template>

<script>
  import { isMobile } from '@/utils/index'

  export default {
    data() {
      return {
        isMobile: isMobile(),
        isMenu: true

      }
    },
    mounted() {

    },
    methods: {
      showMenu() {
        this.isMenu = false
      },
      goNavList(index) {
        this.$emit('hanldeNavList', index)
      },
      goTop() {
        this.$router.push(`/home`)
        document.body.scrollTop = document.documentElement.scrollTop = 0
      }
    },
    computed: {}
  }
</script>

<style lang="scss" scoped>
.logo{
  cursor: pointer;
}
  .pc-header {
    position: relative;

    .header-height {
      height: 56px;
    }
  }

  .header-top {
    position: fixed;
    top: 0;
    left: 0;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    width: 100vw;
    background: #fff;
    /* Nav bar */
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.01), 0px 2px 6px rgba(0, 0, 0, 0.02), 0px 8px 16px rgba(0, 0, 0, 0.03), 0px 24px 48px rgba(0, 0, 0, 0.04);
    backdrop-filter: blur(50px);
    z-index:1111;

    .logo {
      width: 132px;
      height: 40px;
    }

    .nav-list {
      display: flex;
      justify-content: center;

      .item {
        margin: 0 20px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        color: #000000;
        a {
          color: #000000;
        }
      }
    }

    .contact {
      width: 120px;
      height: 40px;
      background: #2788F0;
      border-radius: 8px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 40px;
      text-align: center;
      text-transform: uppercase;
      color: #fff;
      a{
        color: #fff;
      }
    }
  }

  .m-header {
    position: relative;

    .header-height {
      height: 64px;
    }

    .m-top {
      position: fixed;
      height: 64px;
      top: 0;
      left: 0;
      padding: 0 12px;
      width: 100vw;
      box-sizing: border-box;
      background: #fff;
      /* Nav bar */

      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.01), 0px 2px 6px rgba(0, 0, 0, 0.02), 0px 8px 16px rgba(0, 0, 0, 0.03), 0px 24px 48px rgba(0, 0, 0, 0.04);
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 1111;

      .logo {
        width: 132px;
        height: 40px;
      }

      .m-menu {
        width: 18px;
      }
    }

    .nav-list {
      position: fixed;
      width: 100vw;
      height: calc(100vh - 64px);
      top: 64px;
      left: 0;
      background: #fff;
      z-index: 1111;

      .item {
        display: flex;
        height: 60px;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        justify-content: center;
        align-items: center;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
      }
    }
  }
</style>
