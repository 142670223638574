<template>
  <!-- 页面面包屑 -->
  <div class="bread">
    <div class="con-box">
      <span>您所在的位置：</span>
      <a href="/" title="首页">首页</a> &gt;
      <router-link :to="curPath.toPath" :title="curPath.name">{{curPath.name}}</router-link>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      curPath: {
        type: Object,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>
.bread {
  height: 56px;
  padding: 15px 0;
  line-height: 26px;
  font-size: 14px;
}
.bread span {
  margin-right: 10px;
}
.bread a {
  color: #999;
  margin: 0 4px;
}
.bread a:hover, .bread a:last-child {
  color: rgb(27, 161, 9);
}
</style>
