export function isMobile() {
  const windowWidth = window.innerWidth
  //if (windowWidth <= 750 || (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
  if (windowWidth <= 750 ) {
    return true // 手机端
  } else {
    return false // alert('PC端')
  }
}
export function calculateScale() {
  const windowWidth = window.innerWidth > 1920 ? 1920 : window.innerWidth
  let sourceWidth = 1920
  let scale = windowWidth / sourceWidth
  return scale
}
