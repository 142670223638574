<template>
  <div class="home">
    <div class="video-banner">
      <video
        muted="true"
        loop="loop"
        autoplay="true"
        src="https://bridgenebio.s3.us-west-1.amazonaws.com/banner/banner-video.mp4"
        x-webkit-airplay="allow"
        preload="meta"
        webkit-playsinline="true"
        x5-video-player-type="h5-page"
        x5-video-player-fullscreen="true"
        playsinline="true"
        poster=""
      >
        your browser does not support the video tag
      </video>
      <div>
        <ul class="video-list">
          <li>{{ homeBanner.title }}</li>
          <li>{{ homeBanner.description }}</li>
        </ul>
      </div>
    </div>

    <div id="About">
      <!-- <div class="flex-center-container"> -->
      <div
        class="about-wrap eks-scale"
        :data-height="aboutTab ==0 ? 616 : 1616"
        :style="`transform: scale(${scale});`"
      >
        <div class="title">ABOUT US</div>
        <div class="sub_title">
          <div
            :class="aboutTab === 0 ? 'sub_item active' : 'sub_item'"
            @click="changeAbout(0,616)"
          >
            COMPANY
          </div>
          <div
            :class="aboutTab === 1 ? 'sub_item active' : 'sub_item'"
            @click="changeAbout(1,1616)"
          >
            TEAM
          </div>
        </div>
        <div class="about-con" v-if="aboutTab === 0">
          <p>
            BridGene Biosciences is a biotechnology company focusing on
            discovering and developing innovative small molecules that drug high
            value yet previously undruggable targets, providing new paths to
            treat diseases.
          </p>
          <p>
            Currently, about 90% of proteins associated with diseases cannot be
            targeted by traditional therapies due to the lack of a known
            addressable binding site. BridGene intends to change this by using
            its proprietary Chemoproteomics platform, IMTAC™ (Isobaric Mass
            Tagged Affinity Characterization). IMTAC™ enables the screening of
            small molecules against all the proteins in live cells to discover
            drug candidates for high value and hard-to-drug targets that cause
            diseases.
          </p>
          <p>
            Bringing new drugs to previously undruggable targets means new
            therapies with new mechanisms for unmet medical needs. The ultimate
            goal is to expand small molecule drug discovery approaches and scope
            at an unprecedented speed and success rate.
          </p>
        </div>
        <template v-else>
          <div class="about-list">
            <div
              class="about-item"
              v-for="(item, index) in teamMembers"
              :key="index"
              @click="openDia(item.name + ' | ' + item.title, item.htmlContent)"
            >
              <div class="img">
                <img :src="item.imageUrl" />
              </div>
              <div class="text">
                <p>{{ item.name }}</p>
              </div>
              <div class="job">
                {{ item.title }}
              </div>
            </div>
          </div>
          <div class="about-sub-title">Scientific Advisory Board</div>
          <div class="about-list">
            <div
              class="about-item"
              v-for="(item, index) in sabMembers"
              :key="index"
              @click="openDia(item.name + ' | ' + item.title, item.htmlContent)"
            >
              <div class="img">
                <img :src="item.imageUrl" />
              </div>
              <div class="text">
                <p>{{ item.name }}</p>
              </div>
              <div class="job">
                {{ item.title }}
              </div>
            </div>
          </div>
        </template>
      </div>
      <!-- </div> -->
    </div>

    <div class="line"></div>

    <div id="Platform">
      <div
        class="planform eks-scale"
        data-height="1940"
        :style="`transform: scale(${scale});`"
      >
        <div>
          <div class="title">PLATFORM</div>
          <div class="planform-con">
            <p>
              IMTAC™ platform combines cutting-edge technologies including
              covalent chemistry,
            </p>
            <p>chemical proteomics, and quantitative mass spectrometry.</p>
          </div>
          <div class="planform-con-key">
            <div class="key-title">
              <img src="@/assets/imgs/img_IMTAC.png" /> Key Components
            </div>
            <div class="con-key-list-img">
              <div class="img-lottie">
                <lottie-web-cimo
                  ref="key_lottie_web"
                  :animationData="
                    KeyComponentsArrowDefaultOptions.animationData
                  "
                  :path="KeyComponentsArrowDefaultOptions.path"
                  :loop="KeyComponentsArrowDefaultOptions.loop"
                  :animation-speed="
                    KeyComponentsArrowDefaultOptions.animationSpeed
                  "
                  @isLottieFinish="handleLottieFinish"
                />
              </div>
            </div>
            <div class="con-key-list">
              <div class="con-key-item">
                <div class="img">
                  <img src="@/assets/imgs/Key1.gif" />
                </div>
                <div class="text">
                  Well-Designed Covalent Small Molecule Library
                </div>
              </div>
              <div class="con-key-item">
                <div class="img">
                  <img src="@/assets/imgs/Key2.gif" />
                </div>
                <div class="text">Live-Cell Screening</div>
              </div>
              <div class="con-key-item">
                <div class="img">
                  <img src="@/assets/imgs/Key3.gif" />
                </div>
                <div class="text">Quantitative Mass Spec Analysis</div>
              </div>
              <div class="con-key-item">
                <div class="img">
                  <img src="@/assets/imgs/Key4.gif" />
                </div>
                <div class="text">Drug Development</div>
              </div>
            </div>
          </div>
          <div class="planform-con-key">
            <div class="key-title">
              <img src="@/assets/imgs/img_IMTAC.png" /> Innovation
            </div>
            <div class="innovation-con">
              <div class="innovation-top">
                <div class="item item-l">
                  <div class="text">
                    <h1>Proteome-Wide Screening</h1>
                    <p>Live cell screening of the proteome</p>
                  </div>
                  <div class="img">
                    <img src="@/assets/imgs/img_innovation2.png" />
                  </div>
                </div>
                <div class="item item-r">
                  <div class="img">
                    <img src="@/assets/imgs/img_innovation1.png" />
                  </div>
                  <div class="text">
                    <h1>Target-Focused Screening</h1>
                    <p>
                      Live cell screening of high-value targets of interest
                      (e.g. oncogenic mutant)
                    </p>
                  </div>
                </div>
              </div>
              <div class="innovation-mid">
                <div class="item item-l">
                  <div class="text">
                    <h1>Screen in Different Cellular States</h1>
                    <p>Apply different stimuli to cells</p>
                  </div>
                  <div class="img">
                    <img src="@/assets/imgs/img_innovation3.png" />
                  </div>
                </div>
                <div class="mid-img">
                  <div class="mid-bg-img">
                    <lottie-web-cimo
                      ref="imtac_arrow_lottie_web"
                      :animationData="
                        imtacCodeArrowDefaultOptions.animationData
                      "
                      :path="imtacCodeArrowDefaultOptions.path"
                      :loop="imtacCodeArrowDefaultOptions.loop"
                      :animation-speed="
                        imtacCodeArrowDefaultOptions.animationSpeed
                      "
                      @isLottieFinish="handleLottieFinish"
                    />
                  </div>
                  <div class="con-mid">
                    <img src="@/assets/imgs/center.svg" />
                    <div class="text">BridGene’s Unique Technologies</div>
                  </div>
                </div>
                <div class="item item-r">
                  <div class="img">
                    <img src="@/assets/imgs/img_innovation4.png" />
                  </div>
                  <div class="text">
                    <h1>
                      Identify Covalent or Non-Covalent Interacting Proteins
                    </h1>
                    <p>
                      Greatly increase the application scope of chemoproteomic
                      technology
                    </p>
                  </div>
                </div>
              </div>

              <div class="innovation-top">
                <div class="item item-l">
                  <div class="text">
                    <h1>Screen in Different Compartments</h1>
                    <p>Maximal proteomic coverage for particular organelles</p>
                  </div>
                  <div class="img">
                    <img src="@/assets/imgs/img_innovation6.png" />
                  </div>
                </div>
                <div class="item item-r">
                  <div class="img">
                    <img src="@/assets/imgs/img_innovation5.png" />
                  </div>
                  <div class="text">
                    <h1>
                      Well-Designed Covalent Library Targeting Different Amino
                      Acids
                    </h1>
                    <p>
                      Diverse warheads targeting cysteine, lysine, tyrosine,
                      etc., enable the discovery of small molecule ligands for
                      more proteins in the proteome
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <collapse-transition>
      <div class="bottom-video" v-if="showVideo">
        <div class="close-right" @click="closeVideo">X</div>
        <video id="video" controls="controls" autoplay="autoplay" loop>
          <source
            src="https://bridgene-company-web-upload.s3.us-west-1.amazonaws.com/bridgene-video.mp4"
            type="video/mp4"
          />
        </video>
      </div>
      <div class="video" v-else>
        <div class="play" @click="videoPlay()">
          <img src="@/assets/imgs/icn_play.svg" />
        </div>
      </div>
    </collapse-transition>

    <div
      class="partnership eks-scale"
      data-height="3700"
      :style="`transform: scale(${scale});`"
    >
      <ul class="partnership-article">
        <li v-for="article in articles" :key="article.title">
          <div class="title">{{ article.sectionHeader }}</div>
          <div v-if="article.image" class="img-tip">
            <img :src="article.image">
          </div>
          <div class="partnership-con">
            <h1>
              <span v-for="(it, i) in article.title.split('\n')" :key="i" style="display: block">{{ it }}</span>
            </h1>
            <p style="text-align: center">{{ article.publishTime }}</p>
            <p>{{ article.content }}</p>
            <p class="blue">
              <a :href="article.link" target="__blank" style="color: #2788f0">
                {{ article.linkLabel }}
              </a>
            </p>
          </div>
        </li>
      </ul>

      <div class="small-sub-title" style="margin: 30px auto">
        Collaboration Models
      </div>

      <div class="models-con">
        <div class="models-lottie">
          <lottie-web-cimo
            ref="model_lottie_web"
            :animationData="modelsArrowDefaultOptions.animationData"
            :path="modelsArrowDefaultOptions.path"
            :loop="modelsArrowDefaultOptions.loop"
            :animation-speed="modelsArrowDefaultOptions.animationSpeed"
            @isLottieFinish="handleLottieFinish"
          />
        </div>
        <div class="model-con-top">
          <div class="model-con-top-l">
            <img src="@/assets/imgs/img_live cell.png" />
          </div>
          <div class="model-con-top-r">
            <div class="img">
              <img src="@/assets/imgs/img_IMTAC.png" />
            </div>
            <h1>Discover Drugs For Undruggable Targets</h1>
            <p>Focused Live Cell Screening Against Targets of Interest</p>
            <div class="model-border">
              <div class="border-item">Undruggable targets</div>
              <div class="border-item">Low-expressing targets</div>
              <div class="border-item">
                Disease-driving mutants: like KRAS G12C
              </div>
            </div>
          </div>
        </div>
        <div class="model-con-mid">
          <img src="@/assets/imgs/img_IMTAC.png" />
          <div class="title">Deconvolute Phenotypic Screening Hits</div>
        </div>
        <div class="model-con-bottom">
          <div class="bottom-list">
            <div class="list-item">Phenotypic Screening</div>
            <div class="list-item">
              Hits
              <div class="lottie-arrow-down">
                <lottie-web-cimo
                  ref="down_lottie_web"
                  :animationData="downDefaultOptions.animationData"
                  :path="downDefaultOptions.path"
                  :loop="downDefaultOptions.loop"
                  :animation-speed="downDefaultOptions.animationSpeed"
                  @isLottieFinish="handleLottieFinish"
                />
              </div>
            </div>
            <div class="item-mid">
              <lottie-web-cimo
                ref="imtac_lottie_web"
                :animationData="imtacCodeDefaultOptions.animationData"
                :path="imtacCodeDefaultOptions.path"
                :loop="imtacCodeDefaultOptions.loop"
                :animation-speed="imtacCodeDefaultOptions.animationSpeed"
                @isLottieFinish="handleLottieFinish"
              />
            </div>
            <div class="list-item blue">
              New Targets
              <div class="lottie-arrow-down">
                <lottie-web-cimo
                  ref="down_lottie_web"
                  :animationData="downDefaultOptions.animationData"
                  :path="downDefaultOptions.path"
                  :loop="downDefaultOptions.loop"
                  :animation-speed="downDefaultOptions.animationSpeed"
                  @isLottieFinish="handleLottieFinish"
                />
              </div>
            </div>
            <div class="list-item">
              Disease Phenotype
              <div class="lottie-arrow-down">
                <lottie-web-cimo
                  ref="down_lottie_web"
                  :animationData="downDefaultOptions.animationData"
                  :path="downDefaultOptions.path"
                  :loop="downDefaultOptions.loop"
                  :animation-speed="downDefaultOptions.animationSpeed"
                  @isLottieFinish="handleLottieFinish"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="key-title-small">
        <img src="@/assets/imgs/img_IMTAC.png" />
      </div>

      <div class="small-sub-title-mb">
        Screening Of Small Molecule Drugs Or Drug Candidates
      </div>
      <div class="small-sub-title-grey">
        Identify the Small Molecules’ Previously Unknown Targets
      </div>

      <div class="ship-list">
        <div class="ship-l">
          <img src="@/assets/imgs/img_molecule.png" />
          Small Molecule Drugs or Drug Candidates
        </div>
        <div class="ship-m">
          <img src="@/assets/imgs/img_live cell-1.png" />
          <h1>Live-Cell <span class="blue">Proteome-Wide</span> Screening</h1>
          <div class="border-text">
            <p>Access temporary dynamic pockets in native environment</p>
          </div>
        </div>
        <div class="ship-r">
          <div class="line-con">
            <div class="line-r-con">
              <div class="line-r-con-l">
                <img src="@/assets/imgs/line.svg" />
              </div>
              <div class="line-r-con-r">
                <div class="item">
                  <div class="json-l">
                    <lottie-web-cimo
                      ref="lottie_web"
                      :animationData="defaultOptions.animationData"
                      :path="defaultOptions.path"
                      :loop="defaultOptions.loop"
                      :animation-speed="defaultOptions.animationSpeed"
                      @isLottieFinish="handleLottieFinish"
                    />
                  </div>
                  <div class="json-r">Drugs with Unknown Mechanism</div>
                </div>
                <div class="item">
                  <div class="json-l">
                    <lottie-web-cimo
                      ref="lottie_web"
                      :animationData="defaultOptions.animationData"
                      :path="defaultOptions.path"
                      :loop="defaultOptions.loop"
                      :animation-speed="defaultOptions.animationSpeed"
                      @isLottieFinish="handleLottieFinish"
                    />
                  </div>
                  <div class="json-r">Off-Target Toxicity</div>
                </div>
                <div class="item">
                  <div class="json-l">
                    <lottie-web-cimo
                      ref="lottie_web"
                      :animationData="defaultOptions.animationData"
                      :path="defaultOptions.path"
                      :loop="defaultOptions.loop"
                      :animation-speed="defaultOptions.animationSpeed"
                      @isLottieFinish="handleLottieFinish"
                    />
                  </div>
                  <div class="json-r">New Indications</div>
                </div>
              </div>
            </div>
          </div>
          <div class="ship-r-bottom">
            <h1>Quantitative Mass Spec Analysis</h1>
            <div class="border-text">
              <p>1. Identify which targets bind to the small molecules.</p>
              <p>2. Quantify their relative binding affinity.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="line"></div>

    <div id="Pipline">
      <div
        class="pipeline eks-scale"
        data-height="980"
        :style="`transform: scale(${scale});`"
      >
        <div class="title">PIPELINE</div>
        <div class="pipeline-con">
          <img src="@/assets/imgs/pipeline-desktop.png" style="width: 100%;">

          <!-- <table cellspacing="0" cellpadding="0" border="0">
            <thead>
              <th>Target</th>
              <th>Indication</th>
              <th>Discovery</th>
              <th>Preclinical Validation</th>
              <th>Lead Optimization</th>
              <th>IND Enabling</th>
              <th>Phase 1</th>
              <th>Phase 2/3</th>
              <th>Rights</th>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div class="table-td">
                    TEAD
                    <p class="small">(Hippo Signaling Pathway)</p>
                  </div>
                </td>
                <td>Oncology</td>
                <td colspan="6">
                  <div class="td1"><span></span></div>
                </td>
                <td>BridGene</td>
              </tr>
              <tr>
                <td>Undisclosed</td>
                <td>Oncology</td>
                <td colspan="6">
                  <div class="td2"><span></span></div>
                </td>
                <td>BridGene</td>
              </tr>
              <tr>
                <td>Undisclosed</td>
                <td>Oncology</td>
                <td colspan="6">
                  <div class="td3"><span></span></div>
                </td>
                <td>BridGene</td>
              </tr>
              <tr>
                <td>Undisclosed</td>
                <td>Oncology</td>
                <td colspan="6">
                  <div class="td4"><span></span></div>
                </td>
                <td>BridGene</td>
              </tr>
              <tr>
                <td colspan="9" style="background: #f6f8fa">
                  <div class="table-bottom">
                    BridGene is leveraging its proprietary chemoproteomic
                    platform IMTAC™ and expertise in covalent chemistry to
                    advance a deep and diversified pipeline focusing on highly
                    selective small molecule therapeutics targeting previously
                    undruggable targets for innovative new oncology drugs.
                  </div>
                </td>
              </tr>
            </tbody>
          </table> -->
        </div>
      </div>
    </div>
    <div class="line"></div>

    <div id="News" class="news">
      <NewsContainer
        :scale="scale"
        title="MEDIA COVERAGE"
        :news="mediaCoverages"
        all-items-text="All MEDIA COVERAGE"
        all-items-route="news/category/media"
      />
      <NewsContainer
        :scale="scale"
        title="NEWS & EVENTS"
        :news="newsList"
        all-items-text="All NEWS & EVENTS"
        all-items-route="news/category/news"
      />
    </div>

    <!-- <div class="" > -->
    <!-- <div
        id="Contact"
        class="intouch eks-scale getintouch"
        data-height="690"
        data-etra = "0.3"
        :style="`transform: scale(${scale});`"
      > -->
    <div class="contact-wrapper">
      <div id="Contact" class="getintouch">
        <div class="intouch">
          <div class="title">GET IN TOUCH</div>
          <div class="con-box">
            <div class="form">
              <div class="form-item">
                <span class="red">*</span>
                <input type="text" placeholder="Name" v-model="name" />
              </div>
              <div class="form-item">
                <span class="red">*</span>
                <input type="text" placeholder="Email" v-model="email" />
              </div>
              <div class="form-item">
                <span class="red">*</span>
                <textarea
                  placeholder="Comment or Message"
                  v-model="message"
                  maxlength="200"
                />
              </div>
              <div class="submit" @click="submitFrom">SUBMIT</div>
            </div>
            <div class="divider"></div>
            <div class="form-right-text">
              <div class="right-top">
                <h1>BridGene Biosciences</h1>
                <p>Discover Novel Therapeutic Targets and Quality Leads.</p>
                <p>
                  Accelerate Therapeutic Innovation to Improve Human Health.
                </p>
                <p class="copy-right">© 2021 BridGene Biosciences</p>
              </div>
              <div class="right-bottom">
                <h1>Contact</h1>
                <ul class="contact-row-container">
                  <li v-for="contact in contacts" :key="contact.name" class="contact-row">
                    <p class="contact-row-name">
                      {{ contact.name }}
                    </p>
                    <div class="contact-row-item">
                      <img src="@/assets/imgs/icn_Address.svg" />
                      <p>{{ contact.address }}</p>
                    </div>
                    <div class="contact-row-item">
                      <img src="@/assets/imgs/icn_telephone.svg" />
                      <p>{{ contact.phone }}</p>
                    </div>
                  </li>
                </ul>
                
                <div class="item">
                  <img src="@/assets/imgs/icn_email.svg" />
                  {{contacts.usSite.email}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog :title="title" :visible.sync="dialogVisible" width="80%">
      <div class="dialog-con" v-html="htmlContent"></div>
    </el-dialog>
  </div>
</template>

<script>
import { teamMembers, sabMembers } from "@/assets/html/team";
import { calculateScale } from "@/utils/index";
import LottieWebCimo from "@/components/lottie-web-cimo";
import DoneLottie from "@/assets/lottie/Done.json";
import IMTACCORE from "@/assets/lottie/IMTAC-Core.json";
import KeyComponentsArrow from "@/assets/lottie/Key-Components-Arrow.json";
import ModelsArrow from "@/assets/lottie/ModelsArrow.json";
import Down from "@/assets/lottie/Down.json";
import IMTACCOREArrow from "@/assets/lottie/IMTAC-Croe-Arrow-Right.json";
import CollapseTransition from "@/utils/collapse-transition";
// import { mapState, mapGetters } from 'vuex'
import api from "@/api";
import NavArrow from "@/components/NavArrow.vue";
import NewsContainer from "@/components/NewsContainer.vue";
import { article1, article2, homeBanner } from '@/assets/articles'
import {constants} from '@/constants'

export default {
  name: "home",
  created() {},
  data() {
    return {
      homeBanner: homeBanner,
      articles: [ article1, article2 ],
      contacts: constants.contacts,
      scale: 1,
      aboutTab: 0,
      newsTab: 0,
      title: "",
      htmlContent: "",
      dialogVisible: false,
      teamMembers: teamMembers,
      sabMembers: sabMembers,
      showVideo: false,
      defaultOptions: {
        path: "",
        animationData: DoneLottie,
        loop: true,
        animationSpeed: 1,
      },
      KeyComponentsArrowDefaultOptions: {
        path: "",
        animationData: KeyComponentsArrow,
        loop: true,
        animationSpeed: 1,
      },
      imtacCodeDefaultOptions: {
        path: "",
        animationData: IMTACCORE,
        loop: true,
        animationSpeed: 1,
      },
      imtacCodeArrowDefaultOptions: {
        path: "",
        animationData: IMTACCOREArrow,
        loop: true,
        animationSpeed: 1,
      },
      modelsArrowDefaultOptions: {
        path: "",
        animationData: ModelsArrow,
        loop: true,
        animationSpeed: 1,
      },
      downDefaultOptions: {
        path: "",
        animationData: Down,
        loop: true,
        animationSpeed: 1,
      },
      name: "",
      email: "",
      message: "",
      api: api,
      mediaCoverages: [],
      newsList: [],
    };
  },
  mounted() {
    this.getNewsFour();
    this.changeScale();
    this.getMediaCoverages();
    window.addEventListener("resize", this.changeScale);
  },
  methods: {
    openDia(title, content) {
      this.title = title;
      this.htmlContent = content;
      this.dialogVisible = true;
    },
    videoPlay() {
      this.showVideo = true;
    },
    closeVideo() {
      this.showVideo = false;
    },
    changeNews(index) {
      this.newsTab = index;
      this.getNewsFour();
    },
    async getMediaCoverages() {
      try {
        const category = "media"
        const result = await api.getNewsInCategory(category)
        if(result.status === 200) {
          this.mediaCoverages = result.data || [];
        } else {
          this.$message({
            message: result.message,
            type: "warning",
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getNewsFour() {
      try {
        const category = "news"
        const result = await api.getNewsInCategory(category)
        if(result.status === 200) {
          this.newsList = result.data || [];
          console.log(this.newsList);
        } else {
          this.$message({
            message: result.message,
            type: "warning",
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    changeAbout(index,height) {
      this.aboutTab = index;
      this.changeScale(height)
    },
    play() {
      this.$refs.lottie_web.play();
    },
    stop() {
      this.$refs.lottie_web.stop();
    },
    pause() {
      this.$refs.lottie_web.pause();
    },
    handleLottieFinish(isFinish) {
      // 事件处理
    },
    submitFrom() {
      if (!this.name) {
        this.$message({
          message: "Name is required",
          type: "error",
        });
        return false;
      }
      if (!this.email) {
        this.$message({
          message: "Email is required",
          type: "error",
        });
        return false;
      } else {
        const reg = new RegExp(
          "^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$"
        ); // 正则表达式
        if (!reg.test(this.email)) {
          this.$message({
            message: "Email format is incorrect",
            type: "error",
          });
          return false;
        }
      }
      if (!this.message) {
        this.$message({
          message: "Message is required",
          type: "error",
        });
        return false;
      }
      const params = {
        name: this.name,
        email: this.email,
        message: this.message,
      };
      api
        .submitMsg(params)
        .then((res) => {
          if (res.status === 200) {
            this.$message({
              message: "Message Submitted Successfully.",
              type: "success",
            });
            this.name = "";
            this.message = "";
            this.email = "";
          } else {
            this.$message({
              message: res.message,
              type: "warning",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changeScale() {
      this.scale = calculateScale(1920);
      let arr = document.querySelectorAll(".eks-scale");
      this.$nextTick(()=>{
        arr.forEach((item) => {
        console.log(item.dataset.height);
        let height = Number(item.dataset.height);
        // let etra = Number(item.dataset.etra) || 0;
        item.style.height = height * this.scale + "px";
      });
      })
      
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.changeScale);
  },
  components: {
    LottieWebCimo,
    CollapseTransition,
    NavArrow,
    NewsContainer,
  },
};
</script>
<style lang="scss" scoped>
.arrow-btn {
  flex-shrink: 0;
  width: 5rem;
  border-radius: 50%;
  margin: 1rem;
  color: #686868;
  background: transparent;
  border-color: transparent;
}
.arrow-btn:hover {
  background-color: #00000011;
}
.arrow-btn:active {
  scale: 95%;
}
.eks-scale {
  margin: 0 auto;
}
::v-deep .el-dialog {
  border-radius: 10px !important;
}

::v-deep .el-dialog__header {
  margin: 0px 80px;
  padding: 40px 0;

  .el-dialog__title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 52px;
    /* identical to box height, or 150% */

    /* Black */

    color: #000000;
  }

  .el-dialog__headerbtn {
    font-size: 28px;
    right: 80px !important;
    top: 55px !important;
  }
}

::v-deep .el-dialog__body {
  padding: 0 80px 60px 80px;
}

.dialog-con {
  border-top: 1px solid #d7d9dd;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  word-break: normal;
  /* or 160% */

  /* Black */
  color: #000000;

  ::v-deep div,
  ::v-deep p {
    margin: 40px 0;
  }

  ::v-deep li.li-type:before {
    content: "●";
  }
}

.draw-enter-active,
.draw-leave-active {
  transition: all 1s ease;
}

.draw-enter, .draw-leave-to /* .fade-leave-active below version 2.1.8 */ {
  height: 0;
  opacity: 0;
}

.video-banner {
  width: 100vw;

  video {
    width: 100%;
  }
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #d7d9dd;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #d7d9dd;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #d7d9dd;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #d7d9dd;
}

@media (max-width: 1468px) {
  .home {
    margin: 0 auto;
    overflow-x: hidden;
  }

  .con-key-list .con-key-item {
    width: 25% !important;
    margin: 0 !important;
    .img {
      width: 100% !important;
      img {
        width: 100% !important;
      }
    }
  }
  .innovation-top,
  .innovation-mid {
    // .item {
    //   width: 33.33% !important;

    //   .text {
    //     width: 65% !important;
    //   }
    // }
  }
  // .innovation-con {

  //   .innovation-mid .mid-img {
  //     width:30% !important;

  //     .mid-bg-img {
  //       position: absolute;
  //       width: 100%;
  //       z-index: 1;
  //       width: 100% !important;
  //     }

  //     .con-mid {
  //       width: 100px;
  //       position: relative;
  //       z-index: 2;
  //    text-align: center;

  //       img {
  //         width: 100px !important;

  //         margin: 0 auto;
  //         margin-top: -60px !important;
  //       }

  //       .text {
  //         font-family: Montserrat;
  //         font-style: normal;
  //         font-weight: 600;
  //         font-size: 20px;
  //         line-height: 32px;
  //         /* or 160% */

  //         text-align: center;

  //       }
  //     }
  //   }
  // }
  .models-con {
    // width: 100% !important;

    .model-con-top-l {
      // width: 60% !important;

      img {
        width: 100%;
      }
    }

    .model-con-top-r {
      width: 40% !important;
    }
  }
  .model-con-bottom {
    // width: 100% !important;
    // background: url("../../assets/imgs/model_bottom.svg") no-repeat center
    //   center;
    // background-size: 100% auto !important;

    .bottom-list {
      .item-mid {
        // width: 160px !important;
        // height: 160px !important;
      }

      .list-item {
        // width: 140px !important;
        // font-size: 16px !important;
      }
    }
  }
  .ship-list {
    // .ship-l {
    //   width: 28% !important;
    //   img {
    //     width: 100%;
    //   }
    // }
    // .ship-m {
    //   width: 32% !important;

    //   img {
    //     width: 100%;
    //   }
    //   .border-text {
    //     width: 98% !important;
    //     font-size: 16px;
    //     padding: 0 10px !important;
    //     height: auto !important;
    //   }
    // }
    // .ship-r {
    //   width: 40% !important;
    //   .line-r-con-l {
    //     width: 100%;
    //   }
    // }
  }
  .pipeline-con {
    td,
    th {
      // padding: 0 !important;
    }

    .td1 {
      // width: 25vw !important;
    }

    .td2 {
      // width: 20vw !important;
    }

    .td3 {
      // width: 15vw !important;
    }

    .td4 {
      // width: 10vw !important;
    }
  }
}

@media screen and (min-width: 1469px) {
}

@media screen and (max-width: 1150px) {
  .about-list {
    .about-item {
      padding-bottom: 20px !important;

      .img {
        img {
          margin-bottom: 20px !important;
        }
      }

      .text {
        p {
          font-size: 16px !important;
        }
      }

      .job {
        font-size: 16px !important;
      }
    }
  }
}

.video-banner {
  width: 100vw;
  margin: 0 auto;
  position: relative;

  .video-list {
    position: absolute;
    right: 2%;
    bottom: 20%;
    width: 50%;

    li {
      list-style-type: none;
      text-align: center;
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 3vw;
      line-height: 1.333;
      margin-bottom: 8%;
      /* or 125% */

      /* white */

      color: #ffffff;

      text-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
    }
  }
}

.title {
  font-family: Montserrat-Blod;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  color: #000000;
  margin-bottom: 40px;
}

.key-title-small {
  margin-top: 120px;
  text-align: center;
}

.small-sub-title {
  font-family: 'Montserrat-Blod';
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 48px;
  /* identical to box height, or 150% */

  text-align: center;

  /* Black */

  color: #000000;

  // padding: 40px 0;
}

.small-sub-title-mb {
  font-family: 'Montserrat-Blod';
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 48px;
  /* identical to box height, or 150% */

  text-align: center;

  /* Black */

  color: #000000;
  margin: 16px 0;
}

.small-sub-title-grey {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height, or 160% */

  text-align: center;

  /* Gray Blue */

  color: #000000;
}

/*about us*/
.about-wrap {
  padding: 120px 180px 30px 180px;
  width: 1920px;
  box-sizing: border-box;
  transform-origin: 0 0;
  margin-bottom: 5vw;
  .sub_title {
    margin-bottom: 44px;
    display: flex;
    justify-content: center;
    align-items: center;

    .sub_item {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */
      text-align: center;
      text-transform: uppercase;
      margin: 0 12px;
      cursor: pointer;

      /* Black */

      color: #000000;
    }

    .active {
      border-bottom: 2px solid #2788f0;
    }
  }

  .about-list {
    display: flex;
    flex-wrap: wrap;
    /*justify-content:center;*/

    .about-item {
      border: 1px solid #d7d9dd;
      width: calc(25% - 16px);
      border-radius: 8px;
      margin: 0 8px 20px 8px;
      overflow: hidden;
      cursor: pointer;
      padding-bottom: 40px;

      .img {
        width: 100%;
        overflow: hidden;

        img {
          max-width: 100%;
          max-height: 262px;
          width: auto;
          height: auto;
          margin-bottom: 40px;
        }
      }

      .text {
        padding: 0 20px;

        p {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 32px;
          /* identical to box height, or 160% */

          text-align: center;

          /* Black */

          color: #000000;
          word-break: break-word;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }

      .job {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */

        text-align: center;

        /* Gray Blue */

        color: #5f6f8f;
      }
    }

    .about-item:nth-child(4n) {
      margin-right: 0;
    }
  }

  .about-sub-title {
    padding: 20px 0;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height, or 150% */

    text-align: center;

    /* Black */

    color: #000000;
  }

  .about-con {
    p {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 32px;
      color: #000;
      margin-bottom: 20px;
      margin-left: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

/*PLATFORM*/
.planform {
  padding: 120px 180px 30px 180px;
  width: 1920px;
  box-sizing: border-box;
  transform-origin: 0 0;
  margin-bottom: 5vw;
  .planform-con {
    p {
      text-align: center;
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 32px;
      color: #000;

      &:last-child {
        padding-bottom: 80px;
        border-bottom: 1px solid #d7d9dd;
      }
    }
  }

  .planform-con-key {
    .key-title {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 80px;
      margin-bottom: 40px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      padding: 80px 0;

      img {
        margin-right: 16px;
      }
    }

    .con-key-list-img {
      display: flex;
      justify-content: center;
      align-items: center;

      .img-lottie {
        width: 998px;
        height: 128px;
      }
    }

    .con-key-list {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 80px;
      border-bottom: 1px solid #d7d9dd;

      .con-key-item {
        width: 256px;
        margin: 0 32px;

        .img {
          width: 256px;
          height: 256px;
        }

        .text {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 32px;
          text-align: center;
          color: #000000;
        }
      }
    }

    .innovation-con {
      .innovation-mid .mid-img {
        width: 427px;
        height: 320px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        .mid-bg-img {
          position: absolute;
          width: 427px;
          height: 320px;
          z-index: 1;
        }

        .con-mid {
          width: 200px;
          position: relative;
          z-index: 2;

          img {
            width: 200px;
            height: 200px;
            margin-top: 85px;
          }

          .text {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 32px;
            /* or 160% */

            text-align: center;
          }
        }
      }

      .innovation-top,
      .innovation-mid {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;

        .item {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .text {
            width: 336px;

            h1 {
              font-family: Montserrat;
              font-style: normal;
              font-weight: 600;
              font-size: 20px;
              line-height: 32px;
              color: #000000;
            }

            p {
              font-family: Montserrat;
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: #5f6f8f;
            }
          }
        }

        .item-l {
          h1,
          p {
            text-align: right;
          }
        }

        .item-r {
          h1,
          p {
            text-align: left;
          }
        }
      }
      .innovation-top {
        padding: 0 160px;
      }
    }
  }
}

/*PARTNERSHIP*/
.partnership {
  padding: 120px 180px 30px 180px;
  width: 1920px;
  box-sizing: border-box;
  transform-origin: 0 0;
  margin-bottom: 5vw;

  .partnership-article {
    display: flex;
    flex-direction: column;
    gap: 4rem;
  }

  .img-tip {
    display: flex;
    height: 134px;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }

  .partnership-con {
    padding-bottom: 80px;
    border-bottom: 1px solid #d7d9dd;

    h1 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      color: #000;
      margin-bottom: 20px;
      text-align: center;
    }

    p {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      color: #000;
      margin-bottom: 20px;
      margin-left: 10px;
    }

    p.blue {
      color: #2788f0;
      text-align: center;
    }
  }

  .models-con {
    width: 1220px;
    margin: 0 auto;
    position: relative;
    padding-bottom: 30px;

    .models-lottie {
      position: absolute;
      left: 0;
      right: 0;
      top: 90px;
      bottom: 0;
      margin: auto;
      width: 609px;
      height: 1390px;
      z-index: 1;
    }

    .model-con-top {
      z-index: 2;
      position: relative;
      padding-top: 120px;
      display: flex;
      justify-content: space-between;

      .model-con-top-l {
        width: 664px;
        margin-right: 80px;
      }

      .model-con-top-r {
        .img {
          padding-top: 50px;
        }

        h1 {
          font-family: Montserrat;
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 36px;
          /* identical to box height, or 150% */

          /* Black */

          color: #000000;
          margin-bottom: 16px;
        }

        p {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 32px;
          /* identical to box height, or 160% */

          /* Gray Blue */

          color: #5f6f8f;
        }

        .model-border {
          width: 440px;
          padding-top: 40px;

          .border-item {
            padding: 8px 12px;
            height: 40px;
            background: #ffffff;
            /* New Brand */

            border: 1px solid #2788f0;
            box-sizing: border-box;
            border-radius: 8px;
            line-height: 40px;
            margin-right: 12px;
            margin-bottom: 20px;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            /* identical to box height, or 150% */

            /* New Brand */

            color: #2788f0;
            float: left;
          }
        }
      }
    }

    .model-con-mid {
      padding-top: 165px;

      .title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 36px;
        /* identical to box height, or 150% */

        /* Black */

        color: #000000;
        text-align: left;
      }
    }

    .model-con-bottom {
      position: relative;
      z-index: 2;
      width: 1220px;
      height: 396px;
      background: url("../../assets/imgs/model_bottom.svg") no-repeat center
        center;

      .bottom-list {
        display: flex;
        justify-content: flex-end;
        padding-top: 20px;
        height: 220px;
        align-items: center;

        .item-mid {
          margin: 0 20px;
          width: 200px;
          height: 200px;
        }

        .list-item {
          width: 180px;
          height: 104px;
          display: flex;
          justify-content: center;
          align-items: center;

          /* white */

          background: #ffffff;
          /* Line */

          border: 1px solid #d7d9dd;
          box-sizing: border-box;
          border-radius: 52px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 32px;
          /* or 160% */

          text-align: center;

          /* Gray Blue */

          color: #5f6f8f;
          margin: 0 20px;
          position: relative;

          .lottie-arrow-down {
            width: 24px;
            height: 24px;
            position: absolute;
            left: 70px;
            bottom: -12px;
          }
        }

        .blue {
          color: #2788f0;
        }
      }
    }
  }

  .ship-list {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
    margin-left: 10px;

    .ship-l {
      width: 228px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 32px;
      /* or 160% */

      text-align: center;

      /* Black */

      color: #000000;
      img {
        max-width: 100%;
      }
    }

    .ship-m {
      width: 368px;
      text-align: center;

      h1 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
        /* identical to box height, or 160% */

        text-align: center;

        /* Black */

        color: #000000;
        margin-bottom: 20px;

        .blue {
          color: #2788f0;
        }
      }

      .border-text {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
        width: 368px;
        height: 88px;
        color: #5f6f8f;
        border: 2px solid #ffc32d;
        box-sizing: border-box;
        border-radius: 20px;
        padding: 12px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
    }

    .ship-r {
      .ship-r-bottom {
        width: 476px;
        margin: 0 auto;
      }

      .line-r-con {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .line-r-con-l {
          width: 457px;
          margin-right: 40px;
        }

        .line-r-con-r {
          .item {
            width: 212px;
            height: 64px;
            padding: 0 8px;
            /* Line */

            border: 1px solid #d7d9dd;

            border-radius: 20px;
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            .json-l {
              width: 24px;
              height: 24px;
              margin-right: 8px;
            }

            .json-r {
              font-family: Montserrat;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 24px;
              /* or 150% */

              /* Black */

              color: #000000;
            }
          }
        }
      }

      h1 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
        /* identical to box height, or 160% */

        text-align: center;

        /* Black */

        color: #000000;
        margin-bottom: 20px;

        .blue {
          color: #2788f0;
        }
      }

      .border-text {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
        width: 476px;
        height: 120px;
        color: #5f6f8f;
        border: 2px solid #ffc32d;
        box-sizing: border-box;
        border-radius: 20px;
        padding: 12px 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        /*align-items: center;*/
      }
    }
  }
}

.pipeline {
  padding: 120px 180px 30px 180px;
  width: 1920px;
  box-sizing: border-box;
  transform-origin: 0 0;
  margin-bottom: 5vw;
  .pipeline-con {
    margin: 0 auto;

    .table-bottom {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      margin: 0 124px;
      padding: 40px 0;
      text-align: left;
    }

    table {
      border: 1px solid #d7d9dd;
      box-shadow: 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198),
        0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
        0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
        0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
        0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
      border-radius: 16px;
      margin: 40px 0px;
      overflow: hidden;
      vertical-align: middle;
      border-bottom: none;
    }

    .td1 {
      width: 600px;
      height: 16px;

      span {
        display: inline-block;
        width: 100%;
        height: 100%;
        background: #ffc32d;
        -webkit-animation: load 2s ease;
      }
    }

    .td2 {
      width: 430px;
      height: 16px;

      span {
        display: inline-block;
        width: 100%;
        height: 100%;
        background: #2788f0;
        -webkit-animation: load 2s ease;
      }
    }

    .td3 {
      width: 200px;
      height: 16px;

      span {
        display: inline-block;
        width: 100%;
        height: 100%;
        background: #25d8f0;
        -webkit-animation: load 2s ease;
      }
    }

    .td4 {
      width: 74px;
      height: 16px;

      span {
        display: inline-block;
        width: 100%;
        height: 100%;
        background: #094c93;
        -webkit-animation: load 2s ease;
      }
    }

    .table-td {
      width: 217px;

      .small {
        font-size: 16px;
      }
    }

    th {
      background: #f6f8fa;
      height: 120px;
      /*padding: 28px 35px;*/
      box-sizing: border-box;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 32px;
      /* or 160% */

      text-align: center;

      /* Black */

      color: #000000;
    }

    td {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      /* or 160% */
      border-bottom: 1px solid #d7d9dd;
      text-align: center;

      /* Gray Blue */
      height: 96px;
      color: #5f6f8f;
      padding: 0 20px;
    }

    tr td:nth-child(2n) {
      border-right: 1px solid #d7d9dd;
    }

    tr td:nth-child(3n) {
      text-align: left;
      padding-left: 0;
    }
  }
}

.media-coverages {
  padding: 120px 180px 30px 180px;
  width: 1920px;
  box-sizing: border-box;
  transform-origin: 0 0;
  .sub_title {
    margin-bottom: 44px;
    display: flex;
    justify-content: center;
    align-items: center;

    .sub_item {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */
      text-align: center;
      text-transform: uppercase;
      margin: 0 12px;
      cursor: pointer;

      /* Black */

      color: #000000;
    }

    .active {
      border-bottom: 2px solid #2788f0;
    }
  }

  .media-coverages-row-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2rem;
  }

  .media-coverages-row-wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 452px;
  }

  .media-coverages-row {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    transition: transform 1s;

    .coverage-item {
      border: 1px solid #d7d9dd;
      flex: 1 1 0%;
      height: 452px;
      border-radius: 8px;
      overflow: hidden;
      cursor: pointer;

      .img {
        height: 262px;
        margin-bottom: 20px;
        overflow: hidden;

        img {
          max-width: 315px;
          max-height: 262px;
          width: auto;
          height: auto;
        }
      }

      .text {
        padding: 0 20px;
        height: 96px;
        overflow: hidden;

        p {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 32px;
          color: #000;
          word-break: break-word;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }

      .time {
        padding: 10px 20px;
        font-family: Montserrat;
      }

      .btn {
        padding: 4px 12px;
        background: #ffffff;
        /* Line */
        border: 1px solid #d7d9dd;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #2788f0;
        border-radius: 4px;
        margin-left: 20px;
        margin-top: 20px;
      }
    }

    .coverage-item:nth-child(4n) {
      margin-right: 0;
    }
  }

  .news-btn-more {
    width: 144px;
    height: 44px;
    line-height: 44px;
    background: #2788f0;
    border-radius: 8px;
    margin: 40px auto;
    text-align: center;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
    /* white */
    color: #ffffff;
  }

  .group-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px auto;

    .item-button {
      width: 254px;
      margin: 0 32px;
      height: 44px;
      line-height: 44px;
      background: #2788f0;
      border-radius: 8px;
      text-align: center;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      text-transform: uppercase;
      /* white */
      color: #ffffff;
      cursor: pointer;
    }
  }
}

.news {
  padding-top: 82px;
}

.news-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .pb-6 {
    padding-bottom: 24px;
  }

  .list {
    display: flex;
    // border-right: #00000066 solid 2px;

    .list-item {
      display: flex;
      flex-direction: column;
      max-width: 300px;
      padding: 15px;
      border-radius: 6px;
      // border-left: #00000066 solid 2px;
      text-align: left;
      background: transparent;
      border: transparent;
      cursor: pointer;

      img {
        width: 100%;
        max-width: 300px;
      }

      .list-item-time {
        padding-top: 0.7rem;
        font-family: Montserrat;
      } 

      .list-item-title {
        padding-top: 0.7rem;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
        color: #000;
        word-break: break-word;
      }
    }

    .list-item:hover {
      background: #00000008;
      transition: all;
    }
  }

  .group-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px auto;

    .item-button {
      width: 254px;
      margin: 0 32px;
      height: 44px;
      line-height: 44px;
      background: #2788f0;
      border-radius: 8px;
      text-align: center;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      text-transform: uppercase;
      /* white */
      color: #ffffff;
      cursor: pointer;
    }
  }
}

.line {
  height: 4px;
  width: 100%;
  background: #ffc32d;
}

.video {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../../assets/imgs/video-bg.png") no-repeat center center;
  margin-top: 80px;
  background-size: 100% auto;

  .play {
    width: 64px;
    height: 64px;
    cursor: pointer;
  }

  /*video{*/
  /*  width: 100%;*/
  /*  height: 800px;*/
  /*}*/
}

.bottom-video {
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../../assets/imgs/video-bg.png") no-repeat center center;
  margin-top: 160px;
  background-size: 100% auto;
  position: relative;

  .close-right {
    position: absolute;
    top: 10px;
    right: 10px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 60px;
    cursor: pointer;
    z-index: 10;
  }

  video {
    width: 100%;
    height: 600px;
  }
}

.contact-wrapper {
  background: #f6f8fa;
  display: flex;
}
.getintouch {
  background: #f6f8fa;
  max-width: 1050px;
  box-sizing: border-box;
  width: 100%;
  padding: 20px 30px;
  margin: 30px auto;
  display: flex;
  flex: 1;
  // justify-content: center;
  .con-box {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .form {
      width: 50%;
      flex: 1;
      box-sizing: border-box;
      .form-item {
        border: 1px solid #d7d9dd;
        padding: 12px;
        display: flex;
        margin-bottom: 12px;
        border-radius: 8px;
        /*align-items: center;*/
        background: #fff;

        span.red {
          color: #ff0000;
          margin-right: 5px;
        }

        input,
        textarea {
          border: none;
          background: transparent;
          width: 400px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
        }
        textarea {
          height: 180px;
        }
      }

      .submit {
        border-radius: 8px;
        background: #2788f0;
        height: 48px;
        width: 100%;
        color: #fff;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 48px;
        text-align: center;
        cursor: pointer;
      }
    }
    .divider {
      width: 0;
      border-right: 1px solid #d7d9dd;
      margin: 0 30px;
    }
    .form-right-text {
      width: 50%;
      flex: 1;
      .right-top {
        margin-bottom: 40px;
      }

      .right-bottom {
        .item {
          margin-top: 20px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          height: 24px;
          color: #000000;
          align-items: center;
          display: flex;
          img {
            display: inline-block;
            margin-right: 8px;
          }
        }
      }

      h1 {
        font-family: 'Montserrat-Blod';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 12px;
      }

      p {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }

      .copy-right {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        margin-top: 12px;
      }
    }
  }
}

.intouch {
  // padding: 120px 180px 30px 180px;
  // width: 1920px;
  box-sizing: border-box;
  flex: 1;
  width:100%;
  transform-origin: 0 0;
}
@-webkit-keyframes load {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.flex-center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  // border: 1px solid red;
}

.contact-row-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contact-row {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.contact-row-name {
  font-weight: 800 !important;
  font-size: 1rem !important;
  line-height: 1.5rem;
}

.contact-row-item {
  display: flex;
  align-items: start;
  gap: 0.25rem;
}
</style>
