const teamMembers = [
    {
        'name': 'Ping Cao',
        'title': 'CEO',
        'imageUrl': '/img/ping-cao.jpg',
        'htmlContent': `
            <p style="text-align:center"><img style="width:450px; max-width: 100%" src="/img/ping-cao.jpg"></p>
            <p>Dr. Cao is the Co-founder and CEO of BridGene Biosciences, Inc. He has more than 18 years of biopharma industry experience in both large and small molecule drug discovery research. An expert in biophysical/biochemical characterization of proteins/peptides, focusing on leveraging mass spectrometry to support the biopharmaceutical pipeline, he has led teams that have helped advance multiple programs into the clinic in a variety of disease indications, including diabetes, obesity, dyslipidemia, and cardiovascular disease.</p>
            <p>Dr. Cao has held various managerial positions throughout his career. He was responsible for establishing a state-of-the-art mass spectrometry and analytical group at Tularik, Inc., which was acquired by Amgen in 2004. Most recently, Dr. Cao was the head of protein characterization group in the Discovery Analytical Attributes Group at Amgen, Inc. His group engaged in hypothesis-driven targeted proteomics to complement Amgen’s genetic discovery platform, with the goal of facilitating seamless and rapid advancement of projects from research to commercialization. </p>
            <p>Dr. Cao received his Ph.D. from the University of Texas at Austin in Analytical Chemistry and did his Postdoctoral fellowship at Genentech, Inc. He also holds an M.S. in Chemical Engineering from SINOPEC Research Institute of Petroleum Processing in China, as well as a B.S. in Physical Chemistry from Shandong University in Jinan, China. </p>        `
    },
    {
        'name': 'Irene Yuan',
        'title': 'Executive Vice President',
        'imageUrl': '/img/Irene.jpg',
        'htmlContent': `
            <p style="text-align:center"><img style="width:450px; max-width: 100%" src="/img/Irene.jpg"></p>
            <p>Ms Yuan is the Co-Founder and Executive Vice President of BridGene Biosciences. She has more than 15 years of operation management experience. Prior to her role at BridGene, Ms Yuan served as Vice President at MUFG Union Bank and Chief Operation Officer at Redstar Global.</p>

            <p>Ms Yuan obtained her undergraduate education at Nankai University, receiving a B.S. in Economics, and a B.A. in Business Administration and Law. She earned a Master of Sciences in Applied Economics and Finance from University of California, Santa Cruz.</p>        `
    },
    {
        'name': 'Petr Jansa',
        'title': 'Head of Chemistry',
        'imageUrl': '/img/Petr-Jansa.jpg',
        'htmlContent': `
        <p style="text-align:center"><img style="width:450px; max-width: 100%" src="/img/Petr-Jansa.jpg"></p>
        <p>Dr. Petr Jansa, is a highly experienced medicinal chemist with 17+ years of expertise in the field. His primary focus has been on oncology and anti-infective drugs, including antivirals, antibiotics, and antiparasitics. He also possesses additional experience in designing GPCRs ligands, metabolic enzyme inhibitors, nucleosides, phosphonates, cyclophilin inhibitors, phenotypic screening, targeted protein degradation by small molecules (PROTAC), MIDA chemistry, antimetabolites, anti-inflammatory, and anticancer agents. Dr. Jansa has published over 50 peer-reviewed articles and holds 20+ patents or patent applications. He played a key role in the discovery of 5 clinical candidate molecules and served as the lead chemist on 3 discovery projects, including one from project proposal to the identification of a proof-of-concept (POC) molecule that ultimately led to the development of a clinical candidate. Prior to his current role, Petr held various positions at Gilead Sciences. As a project team leader, he successfully led the efforts of discovery projects, taking them from the pre-exploratory stage to full projects.</p><p>Dr. Jansa holds a Ph.D. in organic and medicinal chemistry from Charles University and the Institute of Organic Chemistry and Biochemistry in Prague. Additionally, he earned an M.Sc. (Dipl. Ing.) in organic chemistry from the University of Pardubice, as well as a Certificate in Drug Discovery from the University of California, Berkeley.</p>`
    },
    {
        'name': 'Anna Chen',
        'title': 'Senior Director - Biology',
        'imageUrl': '/img/Anna.jpg',
        'htmlContent': `
            <p style="text-align:center"><img style="width:450px; max-width: 100%" src="/img/Anna.jpg"></p>
            <p>Dr. Chen is a distinguished and accomplished biologist with about 20 years of experience in drug discovery and development, specializing in the areas of CNS, cancer, and autoimmune diseases. Dr. Chen's expertise lies in designing and managing in vivo studies, DMPK studies and toxicity studies. She has a remarkable track record of leading projects from target validation to phase I clinical trials, overseeing critical activities like PCC selection and IND filing. Prior to joining BridGene, Anna held senior leadership positions at organizations such as Accro Bioscience Ltd., Wuxi Shuangliang Bioscience Ltd., and Nanjing Galaxy Biopharma Ltd. Her exceptional management of preclinical pharmacological studies, IND filing, and clinical trials has made significant contributions to the advancements in various oncology projects.</p><p>Dr. Chen conducted her postdoctoral research at Harvard Medical School/Children's Hospital Boston, delving into the mechanisms of Alzheimer's disease and neural regeneration. Prior to her time at Harvard Medical School, she obtained her Ph.D. from the Institute of Neuroscience (ION), Chinese Academy of Sciences, where she focused on the research of developmental neuroscience.</p>
        `
    },
    {
        'name': 'Jeremy Barton',
        'title': 'Chief Medicinal Officer*',
        'imageUrl': '/img/Jeremy.jpg',
        'htmlContent': `
            <p style="text-align:center"><img style="width:450px; max-width: 100%" src="/img/Jeremy.jpg"></p>
            <p>Jeremy Barton, M.D. has over 31 years of experience in all phases of oncology drug development in the pharmaceutical industry in Switzerland, UK and currently in the US. He is now working as a self-employed consultant, advising biotech companies on strategic oncology drug development.<br>Most recent industry responsibilities in the US have included Chief Medical Officer roles at Biogen Idec, eFFECTOR Therapeutics and Mirati Therapeutics. He was also head of early Oncology Development at Pfizer (San Diego) for 5 years overseeing the transition of all Oncology compounds from research through IND to proof of concept studies.</p>
            <p>After obtaining a Masters Degree in Physiological Sciences from Oxford University and his medical degree at University College Hospital Medical School in London, he trained in Internal Medicine and Clinical Oncology in the United Kingdom and practiced for 11 years in the National Health Service.</p>
            <p>Jeremy is Board Certified in Internal Medicine and Clinical Oncology, a Member of the Royal College of Physicians (U.K.), a Fellow of the Royal College of Radiologists (Clinical Oncology) and a Member of the  Faculty of Pharmaceutical Medicine.</p>
            <p>*Part-time</p>
        `
    },
    {
        'name': 'Linda Pullan',
        'title': 'Head of Business Development*',
        'imageUrl': 'https://bridgenebio.s3.us-west-1.amazonaws.com/team/6.jpeg',
        'htmlContent': `
        <p style="text-align:center"><img style="width:450px; max-width: 100%" src="https://bridgenebio.s3.us-west-1.amazonaws.com/team/6.jpeg"></p>
        <p>Dr. Pullan is the Head of Business Development at BridGene Biosciences, Inc. She has over 30 years of BD and drug discovery experience spanning early-stage startups and global pharmaceutical companies.. Previously, Dr. Pullan served as VP of BD at Kosan Biosciences and Head of Oncology and Hematology Licensing at Amgen. </p>
        <p>Dr. Pullan led the team at AstraZeneca that discovered Seroquel, a multi-billion-dollar antipsychotic, prior to switching to licensing. </p>
        <p>Dr. Pullan has participated in the completion of over 100 partnership deals, including one of the largest deals in China (over US $1 billion in milestone payments). </p>
        <p>Dr. Pullan’s specialties include: strategy, identification, evaluation, valuation, and negotiation for biotech and pharma drug candidate opportunities. She has proven success in negotiation for strategic alliances and licensing deals. She is strong in oncology, neuroscience and other fields. </p>
        <p>*Part-time</p>
            `
    },
    {
        'name': 'Wilson Cheung',
        'title': 'CFO*',
        'imageUrl': '/img/wilson.jpg',
        'htmlContent': `
            <p style="text-align:center"><img style="width:450px; max-width: 100%" src="/img/wilson.jpg"></p>
            <p>Wilson is currently CFO Consultant to BridGene Biosciences. Wilson is a seasoned Wall 
            Street veteran with over 30 years of experience in finance, investor relations, cross 
            border transactions, fund-raising, compliance and corporate leadership at both public 
            and private companies including Tessa Therapeutics, Apollomics (Nasdaq: APLM), KBP 
            Biosciences and SciClone Pharmaceuticals (HKSE:6600). Wilson has been a public 
            company CFO since 2004, operating pharma and biotech companies in both US and 
            Asia (Shanghai, Hangzhou, Hong Kong and Singapore) with an extensive network of 
            US and Asia investors. </p>
            
            <p>Wilson graduated with a B.A. in Economics/Business from the University of California, 
            Los Angeles, is a California Certified Public Accountant (inactive), a member of the 
            American Institute of Certified Public Accountants and a certified director of corporate 
            governance from UCLA Anderson School of Management. </p>

            <p>*Part-time</p>
        `
    },
    {
      'name': 'C. Glenn Begley',
      'title': 'Research Consultant*',
      'imageUrl': 'https://bridgenebio.s3.us-west-1.amazonaws.com/team/5.jpeg',
      'htmlContent': `
          <p style="text-align:center"><img style="width:450px; max-width: 100%" src="https://bridgenebio.s3.us-west-1.amazonaws.com/team/5.jpeg"></p>
          <p>C. Glenn Begley MBBS (MD-equivalent), PhD, FRACP, FRCPA, FRCPath, FAHMS is a physician and hematologist/medical oncologist, with a PhD in cell and molecular biology. </p>
          <p>For 4 years (until March 2021) he served as inaugural CEO of BioCurate, a joint initiative of Monash and Melbourne Universities to achieve impact from their biomedical research. </p>
          <p>He served as Chief Scientific Officer at Akriveia Therapeutics (now Xilio), California (2016-2017), and TetraLogic Pharmaceuticals, Pennsylvania (2012-2016), and as non-Executive Director at Oxford BioTherapeutics (2012-2017). He was Vice-President and Global Head of Hematology/Oncology Research at Amgen, Thousand Oaks, California (2002-2012), with responsibility at Amgen’s 5 research sites. </p>
          <p>His scientific responsibilities included Amgen marketed products (filgrastim, pegfilgrastim, erythropoietin, darbepoetin alpha, palifermin, ancestrim, romiplostim, denosumab). Over 25 clinical-stage molecules emerged from his group including fully human monoclonal antibodies, small molecules, protein ligands, antibody-drug conjugates. He was also the internal oncology advocate for in-licensed molecules including, the bi-specific T-cell engager (BiTE) blinatumomab, and the oncolytic virus talimogene laherparepvec (T-Vec). </p>
          <p>While at Amgen he highlighted the issue of research integrity and scientific reproducibility. He has made multiple presentations on this subject including to President Obama’s Science Council, the White House, US National Institutes of Health, US Academies of Science, US National Institute of Standards and Technology, Wellcome Trust, NHMRC, and numerous Universities, Research Institutes and companies. </p>
          <p>Prior to Amgen, he had over 20 years of clinical and research experience in medical oncology/hematology. His early research first described human G-CSF. In later clinical studies he first demonstrated that G-CSF-“mobilized” blood stem cells hastened hematopoietic recovery compared with bone marrow transplantation (so called “stem cell transplantation”). </p>
          <p>His honors include being elected as the first Foreign Fellow to the American Society of Clinical Investigation (2000), the Association of American Physicians (2008), to the Research “Hall of Fame” at his alma mater, the Royal Melbourne Hospital (2014), to the Australian Academy of Health and Medical Sciences (2014). </p>
          <p>He has published over 200 papers that have been widely cited (~25,000 citations; h-index 77; i10-index 188, source Google Scholar, August 2020). His TED-x seminar “The Complex Biology of Cancer” has >100,000 views. </p>
          <p>*Part-time</p>
      `
  },
]

const sabMembers = [
    {
        'name': 'Kevan Shokat, Ph.D.',
        'title': 'Professor',
        'imageUrl': 'https://bridgenebio.s3.us-west-1.amazonaws.com/team/sab1.jpeg',
        'htmlContent': `
        <p style="text-align:center"><img style="width:450px; max-width: 100%" src="https://bridgenebio.s3.us-west-1.amazonaws.com/team/sab1.jpeg"></p>
        <p>Dr. Shokat is a member of National Academy of Sciences, the Institute of Medicine, and the American Academy of Arts and Sciences. Dr. Shokat is a Professor and Chair in the Department of Cellular and Molecular Pharmacology at University of California, San Francisco, a Professor in the Department of Chemistry at University of California, Berkeley, and an Investigator with the Howard Hughes Medical Institute. </p>
        <p>After earning his Ph.D. in Organic Chemistry at UC Berkeley with Professor Peter Schultz, and post-doctoral work in immunology at Stanford University with Professor Chris Goodnow, Dr. Shokat began his independent research career at Princeton University where he was promoted from Assistant to Associate Professor in four years. </p>
        <p>Dr. Shokat is a leader in the field of chemical genetics and is particularly focused on development of new chemical approaches for studying protein and lipid kinase signaling. He has received numerous awards including being named a fellow of several prestigious research foundations including the Pew Foundation, Searle Foundation, Sloan Foundation, Glaxo-Wellcome Foundation, and the Cotrell Foundation. Dr. Shokat has also received the Eli Lilly Award, given to the most promising biological chemist in the country under the age of 37. </p>
         `
    },
    {
        'name': 'Arieh Warshel',
        'title': 'Professor',
        'imageUrl': 'https://bridgenebio.s3.us-west-1.amazonaws.com/team/sab2.jpeg',
        'htmlContent': `
        <p style="text-align:center"><img style="width:450px; max-width: 100%" src="https://bridgenebio.s3.us-west-1.amazonaws.com/team/sab2.jpeg"></p>
            <p>Arieh Warshel is an Israeli-American biochemist and biophysicist. He is a pioneer in computational studies on functional properties of biological molecules. In 1969 he earned PhD degrees in Chemical Physics with Shneior Lifson at Weizmann Institute of Science, Israel. After his PhD, he did postdoctoral work at Harvard University until 1972, and from 1972 to 1976 he returned to the Weizmann Institute and worked for the Laboratory of Molecular Biology, Cambridge, England. In 1976, he joined the faculty of the Department of Chemistry at USC. In 2013, he received the 2013 Nobel Prize in Chemistry, together with Michael Levitt and Martin Karplus for “the development of multiscale models for complex chemical systems”.</p>
            <p>Selected Honors and Awards:</p>
            <ul>
            <li><b>Annual Award of the International Society of Quantum Biology and Pharmacology (1993)</b></li>
            <li>Fellow of the Royal Society of Chemistry (2008)</li>
            <li>Member of the United States National Academy of Sciences (2009)</li>
            <li>RSC Soft Matter and Biophysical Chemistry Award (2012）</li>
            <li>Nobel Prize in Chemistry (2013)  </li>
            <ul>
        `
    },
    {
        'name': 'Arthur Weiss, Ph.D.',
        'title': 'Professor',
        'imageUrl': 'https://bridgenebio.s3.us-west-1.amazonaws.com/team/sab3.jpeg',
        'htmlContent': `
        <p style="text-align:center"><img style="width:450px; max-width: 100%" src="https://bridgenebio.s3.us-west-1.amazonaws.com/team/sab3.jpeg"></p>
        <p>Dr. Weiss is a member of the National Academy of Sciences, Institute of Medicine and the American Academy of Arts and Sciences. He is currently an Investigator at the Howard Hughes Medical Institute, and the Ephraim P. Engelman Distinguished Professor of Rheumatology at the University of California, San Francisco. </p>
        <p>Dr. Weiss earned a Ph.D. (Immunology, 1978) and an M.D. (1979) from the University of Chicago. He held a postdoctoral fellowship at the Swiss Institute for Experimental Cancer Research in Lausanne, Switzerland and completed his training in internal medicine and rheumatology at UCSF. In 1985, Dr. Weiss joined the faculty of the Division of Rheumatology at University of California, San Francisco. </p>
        `
    },
    {
        'name': 'Chao Zhang, Ph.D.',
        'title': 'Scientific Founder / Scientific Advisory Board Chair',
        'imageUrl': 'https://bridgenebio.s3.us-west-1.amazonaws.com/team/sab4.jpeg',
        'htmlContent': `
        <p style="text-align:center"><img style="width:450px; max-width: 100%" src="https://bridgenebio.s3.us-west-1.amazonaws.com/team/sab4.jpeg"></p>
        <p>Dr. Zhang serves as Associate Professor of Chemistry in University of Southern California. He has 20 years of drug discovery experience, authored more than 100 scientific papers,  including articles published in in Nature and Science, and holds multiple licensed patents. Professor Zhang earned a B.S. from Peking University and a Ph.D. from Princeton University. </p>
        <p>Since 1998, Dr. Zhang has conducted research into protein kinases and is one of the world’s top scientists in the field. Professor Kevan Shokat, the founder of the protein kinase field and another BridGene SAB member, served as Dr. Chao’s Ph.D. and postdoctoral advisor. </p>
        <p>Since 2004, Dr. Zhang has been engaged in research in the field of covalent kinase inhibitors. In 2005, he and Professor Jack Taunton jointly published a general method for the efficient discovery of covalent kinase inhibitors in Science. This method was subsequently widely used in the industry and opened the era of targeted therapy for covalent kinase inhibitors, including Ibrutinib and Teresa (annual sales for each is approximately $2 billion). </p>
        <p>Since 2011, Dr. Zhang has established his own laboratory at the University of Southern California, engaging in research in chemical proteomics, becoming one of the top scientists in this field. </p>
        `
    }
]

export {
    teamMembers,
    sabMembers
  }
