import httpCreator from '../assets/js/common-http'

let http = httpCreator()

//  提交表单 
// * done  refactor 
function submitMsg(params) {
  let url = '/message/add'
  return new Promise((resolve, reject) => {
    http.post(url, params).then(resolve).catch(reject)
  })
}

// 从某一个category 获取 前几个， 首页用
// function getRecentNewsInCategory(categoryInUrl, number) {
//   let url = `/news-items?_sort=publishTime:DESC&_limit=${number}&category.categoryInUrl=${categoryInUrl}`
//   return new Promise((resolve, reject) => {
//     http.get(url).then(resolve).catch(reject)
//   })
// }
// * done  refactor 
function getRecentNewsInCategory(category, number, publishTime = 'desc') {
  let url = `/post/query`
  let data = {
    size: number,
    page: 1,
    published: true,
    type: category,
    publishTime
  }
  return new Promise((resolve, reject) => {
    http.post(url, data).then(res => {
      let { data, code } = res.data
      let t = {}
      t.status = code
      t.data = data.data
      return resolve(t)
    }).catch(reject)
  })
}

// category  /news-items?category.category=news
// function getNewsInCategory(categoryInUrl) {
//   return new Promise((resolve, reject) => {
//     http.get(`/news-items?_sort=publishTime:DESC&category.categoryInUrl=${categoryInUrl}`).then(resolve).catch(reject)
//   })
// }

// * done  refactor 
function getNewsInCategory(category, publishTime = 'desc') {
  let url = `/post/query`
  let data = {
    page: 1,
    published: true,
    type: category,
    publishTime
  }
  return new Promise((resolve, reject) => {
    http.post(url, data).then(res => {
      let { data, code } = res.data
      let t = {}
      t.status = code
      t.data = data.data
      return resolve(t)
    }).catch(reject)
  })
}

// tag
function getNewsInTag(tagInUrl) {
  return new Promise((resolve, reject) => {
    http.get(`/news-items?_sort=publishTime:DESC&tag.tagInUrl=${tagInUrl}`).then(resolve).catch(reject)
  })
}

// done 未使用 列表页获取所有的新闻
function getAllNews(params) {
  let url = '/news-items'
  return new Promise((resolve, reject) => {
    http.get(url, params).then(resolve).catch(reject)
  })
}

// 列表页右侧
// function getNewsFive(params) {
//   let url = '/news-items?_sort=publishTime:DESC&_limit=5'
//   return new Promise((resolve, reject) => {
//     http.get(url, params).then(resolve).catch(reject)
//   })
// }
// * done  refactor 
function getNewsFive(publishTime = 'desc') {
  let url = `/post/query`
  let data = {
    page: 1,
    size: 5,
    published: true,
    publishTime
  }
  return new Promise((resolve, reject) => {
    http.post(url, data).then(res => {
      let { data, code } = res.data
      let t = {}
      t.status = code
      t.data = data.data
      console.log(t);
      return resolve(t)
    }).catch(reject)
  })

}
 // * done  refactor tags
function getTags(params) {
  let url = '/tag'
  return new Promise((resolve, reject) => {
    http.get(url, params).then(res => {
      let { data, code } = res.data
      let t = {}
      t.status = code
      t.data = data
      return resolve(t)
    }).catch(reject)
  })
}
// function getTags(params) {
//   let url = '/news-tags'
//   return new Promise((resolve, reject) => {
//     http.get(url, params).then(resolve).catch(reject)
//   })
// }
// detail
// function getNewsDetail(id) {
//   return new Promise((resolve, reject) => {
//     http.get(`/news-items/${id}`).then(resolve).catch(reject)
//   })
// }
// 新闻详情
// function getNewsDetail(titleInUrl) {
//   return new Promise((resolve, reject) => {
//     http.get(`/news-items?titleInUrl=${titleInUrl}`).then(resolve).catch(reject)
//   })
// }
// * done  refactor 
function getNewsDetail(titleInUrl) {
  let url = `/post/by/inurl/${titleInUrl}`
  return new Promise((resolve, reject) => {
    http.get(url).then(res => {
      let { data, code } = res.data
      let t = {}
      t.status = code
      t.data = [data]
      return resolve(t)
    }).catch(reject)
  })
}
function goToDetail(item) {
  if (item.isMedia) {
    window.open(item.mediaUrl, "_blank");
  } else {
    window.open(`/news/${item.titleInUrl}`, "_blank");
  }
}

export default {
  submitMsg,
  getAllNews,
  getNewsInCategory,
  getNewsInTag,
  getRecentNewsInCategory,
  getNewsFive,
  getTags,
  getNewsDetail,
  goToDetail
}
