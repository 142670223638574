import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: Home
  },
  {
    path: '/careers',
    name: 'careers',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Careers.vue')
  },
  {
    path: '/partnership',
    name: 'partnership',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Partnership.vue')
  },
  {
    path: '/news/category/:categoryOrTag',
    name: 'category',
    component: () => import(/* webpackChunkName: "contact" */ '../views/NewsCategoryOrTag.vue')
  }, {
    path: '/news/tag/:categoryOrTag',
    name: 'tag',
    component: () => import(/* webpackChunkName: "contact" */ '../views/NewsCategoryOrTag.vue')
  }, {
    path: '/news/:id',
    name: 'detail',
    component: () => import(/* webpackChunkName: "product" */ '../views/NewsDetail.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: 'active'
  // linkExactActiveClass: 'ex-active'
})

export default router
