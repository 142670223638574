<template>
  <div class="footer-con">
    <div class="m-footer" v-if="isMobile">
      <div class="footer-list">
        <div class="item"><img src="@/assets/imgs/icn_copyright.png">copyright © 2021 BridGene Biosciences</div>
        <div class="item"><img src="@/assets/imgs/icn_location.png">75 Nicholson Ln, San Jose, CA 95134, United States
        </div>
        <div class="item"><img src="@/assets/imgs/icn_telephone.png">1-408-498-8127</div>
        <div class="item"><img src="@/assets/imgs/icn_email.png">info@bridgenebiosciences.com</div>
      </div>
    </div>
    <div class="footer" v-else>
      <div class="footer-list">
        <div class="item"><img src="@/assets/imgs/icn_copyright.png">copyright © 2021 BridGene Biosciences</div>
        <div class="item"><img src="@/assets/imgs/icn_location.png">75 Nicholson Ln, San Jose, CA 95134, United States
        </div>
        <div class="item"><img src="@/assets/imgs/icn_telephone.png">1-408-498-8127</div>
        <div class="item"><img src="@/assets/imgs/icn_email.png">info@bridgenebiosciences.com</div>
      </div>
    </div>
  </div>
</template>

<script>
  import { isMobile } from '@/utils/index'

  export default {
    data() {
      return {
        isMobile: isMobile()
      }
    },
    mounted() {

    },
    methods: {},
    computed: {}
  }
</script>

<style lang="scss" scoped>
  .m-footer {
    display: flex;
    flex-direction: column;
    padding: 24px 16px;
    /* Background */
    width: 100%;
    background: #F6F8FA;
    box-sizing: border-box;

    .footer-list {
      .item {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        display: flex;
        height: 20px;
        align-items: center;
        /* identical to box height, or 133% */

        /* Gray Blue */

        color: #5F6F8F;
        margin-bottom: 12px;

        img {
          margin-right: 4px;
        }

      }
    }
  }

  /*页脚footer*/
  .footer {
    @media screen and (max-width: 1400px) {
      .footer-list {
        width: 96%;
      }
    }

    @media screen and (min-width: 1468px) {
      .footer-list {
        width: 1468px;
      }
    }
    background: #F6F8FA;
    color: #bbb;
    width: 100%;
    height: 64px;
    align-items: center;

    .footer-list {
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 64px;

      .item {
        display: flex;
        align-items: center;
        align-items: center;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;

        /* identical to box height, or 167% */

        /* Gray Blue */

        color: #5F6F8F;

      }
    }
  }

</style>
