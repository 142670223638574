<template>
  <div class="index-page">
    <m-home v-if="isMobile"/>
    <home v-else/>
  </div>
</template>

<script>
  import { isMobile } from '@/utils/index'
  import Home from '@/views/pc/Home'
  import mHome from '@/views/m/mHome'

  export default {
    name: 'indexPage',
    components: {
      Home,
      mHome
    },
    created() {

    },
    data() {
      return {
        screenWidth: '', // 窗口宽度
        isMobile: isMobile()
      }
    },
    mounted() {
      // 获取当前浏览器宽高
      this.screenWidth = window.innerWidth
      // 监听窗口变化
      window.onresize = () => {
        return (() => {
          // 重新赋值最新窗口数据
          this.screenWidth = window.innerWidth
          if (this.screenWidth <= 750) {
            this.isMobile = true
          } else {
            this.isMobile = false
          }
        })()
      }
    },
    computed: {},
    methods: {},
    watch: {
      /* 监听 */
      isMobile(val) {
        location.reload()
        console.log(val)
      }
    }
  }
</script>
<style lang="scss" scoped>
</style>
