<template>
	<!--焦点图-->
	<div class="banner">
    <div class="footer_btn"><img src="@/assets/imgs/jiantou.png" alt="" class="animate-bounce-down" /></div>
    <a class="arrow-left" href="#"></a>
		<a class="arrow-right" href="#"></a>
		<swiper :options="swiperOption" v-if="slides.length">
			<!-- <swiper-slide style="background: url(/images/banner1.jpg) no-repeat center;"></swiper-slide> -->
			<swiper-slide v-for="slide in slides" :key="slide.id" :style="{ background: 'url('+ slide.imgSrc +') no-repeat center'}"></swiper-slide>
		</swiper>
		<!-- <div class="swiper-container" id="bannerSwiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide">

        </div>
        <div class="swiper-slide" style="background: url(/images/banner2.jpg) no-repeat center;">

        </div>
      </div>
		</div>-->
		<div class="pagination bannerpagination"></div>
	</div>
	<!--焦点图结束-->
</template>

<script>
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import api from '@/api'

export default {
	data() {
		return {
			swiperOption: {
				loop: true,
				speed: 1000,
				grabCursor: true,
				parallax: true,
				// autoHeight:true,
				autoplay: {
					delay: 5000,
					disableOnInteraction: false
				},
				pagination: {
					el: '.bannerpagination',
					clickable: true
				},
				navigation: {
					nextEl: '.arrow-right',
					prevEl: '.arrow-left'
				}
      },
      slides: []
		}
  },
  mounted() {
    this.loadSwipers()
  },
  methods: {
    loadSwipers() {
      api.focus().then(res => {
        console.log('轮播图:', res.data)
        if (res.data.errorCode === 0) {
          this.slides = res.data.data
        }
      }).catch(err => {
        console.log(err)
      })
    }
  },
	components: {
		swiper,
		swiperSlide
	}
}
</script>

<style lang="scss" scoped>
  .footer_btn{text-align:center;height:47px;}
  @keyframes bounce-down {
    25% {transform: translateY(-3px);}
    50%, 100% {transform: translateY(0);}
    75% {transform: translateY(3px);}
  }
  .animate-bounce-down{display:none;-webkit-animation: bounce-down 1s linear infinite;animation: bounce-down 1s linear infinite;
  }
  .banner {
	width: 100%;
	height: 900px;
	text-align: center;
	overflow: hidden;
	position: relative;
  padding-top: 96px;
}
.banner .swiper-container {
	height: 100%;
}
.banner .arrow-left {
  background: url(~@/assets/imgs/banner_arrow.png) no-repeat left top;
  position: absolute;
  left: 20px;
  top: 50%;
  margin-top: -35px;
  width: 70px;
  height: 70px;
  z-index:800;
}
.banner .arrow-right {
  background: url(~@/assets/imgs/banner_arrow.png) no-repeat left bottom;
  position: absolute;
  right: 20px;
  top:50%;
  margin-top: -35px;
  width: 70px;
  height: 70px;
  z-index:800;
}
.banner .pagination {
  position: absolute;
  left: 0;
  text-align: center;
  bottom: 5px;
  width: 100%;
  z-index:800;
}
::v-deep.banner .swiper-pagination-bullet {
  display: inline-block;
  width: 60px;
  height: 6px;
  opacity:1;
  border-radius: 1px;
  background: white;
  margin: 0 3px;
  cursor: pointer;
  transition: width 0.3s ease-in-out;
}
::v-deep.banner .swiper-pagination-bullet-active {
	background: #006835;
	/* width:12px; */
}
</style>
