export const constants = {
  contacts: {
    usSite: {
      name: 'U.S. Site',
      address: '75 Nicholson Ln, San Jose, CA 95134',
      phone: '1-408-498-8127',
      email: 'info@bridgenebiosciences.com'
    },
    cnSite: {
      name: 'China Site',
      address: '2nd Floor, Building C17, BioBAY, No. 218 Xinghu Street, Suzhou Industrial Park, Suzhou, China',
      phone: '0512-68663220',
      email: 'info@bridgenebiosciences.com'
    },
  }
}
