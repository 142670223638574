<template>
  <div>
    <lay-header/>
    <transition name="slide" mode="out-in">
      <router-view/>
    </transition>
    <lay-footer/>
  </div>
</template>

<script>
  import { LayHeader, LayFooter } from '@/views/layout'

  export default {
    data() {
      return {
      }
    },
    created() {

    },
    mounted() {
    },
    methods: {},
    components: {
      LayHeader,
      LayFooter
    }
  }
</script>
<style lang="scss">
body{
  overflow-x: hidden ;
}
</style>
