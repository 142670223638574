<template>
  <div
    class="image-container eks-scale"
    data-height="740"
    :style="`transform: scale(${scale});`"
  >
    <div class="title">{{ title }}</div>

    <div class="row-container" v-if="news && news.length > 0">
      <button class="arrow-btn" @click="prevItem">
        <NavArrow point-to="left"/>
      </button>
      <div class="row-wrapper">
        <div class="row" :style="{transform: `translate(${newsRowTranslate})`}">
          <div
            v-for="(item, index) in news"
            :key="index"
            ref="news_item_ref"
            class="item"
            @click="api.goToDetail(item)"
          >
            <div class="img">
              <img
                :src="item.headerImage"
                v-if="item.headerImage"
              />
            </div>
            <p class="time">{{ item.publishTime.split('T')[0]  }}</p>
            <div class="text">
              <p>{{ item.title }}</p>
            </div>
            <button class="btn" v-if="item.tag">
              {{ item.tag.tagName }}
            </button>
          </div>
        </div>
      </div>
      <button class="arrow-btn" @click="nextItem">
        <NavArrow point-to="right"/>
      </button>
    </div>

    <div class="group-btns">
      <div class="item-button" @click="goAllItems">
        {{ allItemsText }}
      </div>
    </div>
  </div>
</template>

<script>
import NavArrow from "@/components/NavArrow.vue";
import api from "@/api";

export default {
  name: 'NewsContainer',
  data() {
    return {
      ITEM_DISPLAY_NUM: 4,
      currentItemIndex: 0,
      newsRowTranslate: '0px',
      api: api,
    }
  },
  props: ['scale', 'title', 'news', 'allItemsText', 'allItemsRoute'],
  components: {
    NavArrow,
  },
  methods: {
    indexIsValid(index) {
      if(index < 0) {return false}
      if(index > (this.news.length - this.ITEM_DISPLAY_NUM)) {return false}
      return true
    },
    prevItem() {
      let predict = this.currentItemIndex - 1
      if(this.indexIsValid(predict)) {
        this.currentItemIndex = predict
      }
    },
    nextItem() {
      let predict = this.currentItemIndex + 1
      if(this.indexIsValid(predict)) {
        this.currentItemIndex = predict
      }
    },
    goAllItems() {
      window.open(this.allItemsRoute, "_blank");
    },
  },
  created() {
  },
  mounted() {
  },
  watch: {
    currentItemIndex(newIndex, _old) {
      const el = this.$refs.news_item_ref
      if(el === undefined) {
        this.newsRowTranslate = '0px'
      }
      const ITEM_GAP = 20
      const translate = -1 * newIndex * (el[0].offsetWidth + ITEM_GAP)
      this.newsRowTranslate = `${translate}px`
    }
  }
}
</script>

<style lang="scss" scoped>
.arrow-btn {
  flex-shrink: 0;
  width: 5rem;
  border-radius: 50%;
  margin: 1rem;
  color: #686868;
  background: transparent;
  border-color: transparent;
}
.arrow-btn:hover {
  background-color: #00000011;
}
.arrow-btn:active {
  scale: 95%;
}
.eks-scale {
  margin: 0 auto;
}
.image-container {
  padding: 0px 180px 0px 180px;
  width: 1920px;
  box-sizing: border-box;
  transform-origin: 0 0;

  .title {
    font-family: Montserrat-Blod;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 60px;
    text-align: center;
    color: #000000;
    margin-bottom: 40px;
  }

  .sub_title {
    margin-bottom: 44px;
    display: flex;
    justify-content: center;
    align-items: center;

    .sub_item {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */
      text-align: center;
      text-transform: uppercase;
      margin: 0 12px;
      cursor: pointer;

      /* Black */

      color: #000000;
    }

    .active {
      border-bottom: 2px solid #2788f0;
    }
  }

  .row-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2rem;
  }

  .row-wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 430px;
  }

  .row {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    transition: transform 1s;

    .item {
      border: 1px solid #d7d9dd;
      flex: 1 1 0%;
      height: 430px;
      border-radius: 8px;
      overflow: hidden;
      cursor: pointer;

      .img {
        padding-bottom: 40px;
        overflow: hidden;

        img {
          width: 315px;
          height: 200px;
          object-fit: cover;
        }
      }

      .text {
        padding: 0 20px;
        height: 96px;
        overflow: hidden;

        p {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 32px;
          color: #000;
          word-break: break-word;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }

      .time {
        padding: 10px 20px;
        font-family: Montserrat;
      }

      .btn {
        padding: 4px 12px;
        background: #ffffff;
        /* Line */
        border: 1px solid #d7d9dd;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #2788f0;
        border-radius: 4px;
        margin-left: 20px;
        margin-top: 20px;
      }
    }

    .item:nth-child(4n) {
      margin-right: 0;
    }
  }

  .group-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px auto;

    .item-button {
      width: 254px;
      margin: 0 32px;
      height: 44px;
      line-height: 44px;
      background: #2788f0;
      border-radius: 8px;
      text-align: center;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      text-transform: uppercase;
      /* white */
      color: #ffffff;
      cursor: pointer;
    }
  }
}
</style>
