<template>
  <div class="home">
    <div class="video-banner">
      <img src="@/assets/imgs/wechat-banner.jpg" v-if="isWx">
      <video v-else id="ckplayer_a1"  muted autoplay="true" loop="loop"
             :src="realScreenUrl"
            x5-video-player-type="h5" preload="metadata" playsinline="true"
             webkit-playsinline="true"  x-webkit-airplay="true" x5-video-orientation="portraint" x5-video-player-fullscreen="true">
        your browser does not support the video tag
      </video>
      <div class="con-con">
        <ul class="video-list">
          <li>{{ homeBanner.title }}</li>
          <li>{{ homeBanner.description }}</li>
        </ul>

      </div>
    </div>
    <div class="con-con" id="About">
      <div class="about-wrap">
        <div class="title">ABOUT US</div>
        <div class="sub_title">
          <div :class="aboutTab===0?'sub_item active' :'sub_item'" @click="changeAbout(0)">COMPANY</div>
          <div :class="aboutTab===1?'sub_item active' :'sub_item'" @click="changeAbout(1)">TEAM</div>
        </div>
        <div class="about-con" v-if="aboutTab===0">
          <p>BridGene Biosciences is a biotechnology company focusing on discovering and developing innovative small
            molecules that drug high value yet previously undruggable targets, providing new paths to treat
            diseases. </p>
          <p>Currently, about 90% of proteins associated with diseases cannot be targeted by traditional therapies due
            to the lack of a known addressable binding site. BridGene intends to change this by using its proprietary
            Chemoproteomics platform, IMTAC™ (Isobaric Mass Tagged Affinity Characterization). IMTAC™ enables the
            screening of small molecules against all the proteins in live cells to discover drug candidates for high
            value and hard-to-drug targets that cause diseases. </p>
          <p>Bringing new drugs to previously undruggable targets means new therapies with new mechanisms for unmet
            medical needs. The ultimate goal is to expand small molecule drug discovery approaches and scope at an
            unprecedented speed and success rate. </p>
        </div>
        <template v-else>
          <div class="about-list">
            <div class="about-item" v-for="(item , index) in teamMembers" :key="index"
                 @click="openDia(item.name+' | '+item.title,item.htmlContent)">
              <div class="img">
                <img :src="item.imageUrl">
              </div>
              <div class="text">
                <p>{{item.name}} </p>
              </div>
              <div class="job">
                {{item.title}}
              </div>
            </div>
          </div>
          <div class="about-sub-title">Scientific Advisory Board</div>
          <div class="about-list">
            <div class="about-item" v-for="(item , index) in sabMembers" :key="index"
                 @click="openDia(item.name + ' | '+ item.title,item.htmlContent)">
              <div class="img">
                <img :src="item.imageUrl">
              </div>
              <div class="text">
                <p>{{item.name}} </p>
              </div>
              <div class="job">
                {{item.title}}
              </div>

            </div>
          </div>
        </template>

      </div>
    </div>

    <div class="line"></div>
    <div class="con-con" id="Platform">
      <div class="planform">
        <div class="title">PLATFORM</div>
        <div class="planform-con">
          <p>IMTAC™ platform combines cutting-edge technologies including covalent chemistry,</p>
          <p> chemical proteomics, and quantitative mass spectrometry. </p>
        </div>
        <div class="planform-con-key">
          <div class="key-title">
            <img src="@/assets/imgs/img_IMTAC.png"> Key Components
          </div>
          <div class="gif-con">
            <div class="gif-item">
              <img src="@/assets/imgs/Key1.gif"/>
              <p>Well-designed Covalent Small Molecule Library</p>
            </div>
            <div class="gif-item">
              <img src="@/assets/imgs/Key2.gif"/>
              <p>Live-Cell Screening</p>
            </div>
            <div class="gif-item">
              <img src="@/assets/imgs/Key3.gif"/>
              <p>Quantitative Mass Spec Analysis</p>
            </div>
            <div class="gif-item">
              <img src="@/assets/imgs/Key4.gif"/>
              <p>Drug Development</p>
            </div>
          </div>
        </div>
        <div class="planform-con-key">
          <div class="key-title">
            <img src="@/assets/imgs/img_IMTAC.png"> Innovation
          </div>
          <div class="innovation-con">
            <div class="innovation-l">
              <div class="innovation-item">
                <div class="img">
                  <img src="@/assets/imgs/img_innovation1.png">

                </div>
                <div class="text-h">Target-Focused Screening</div>
                <div class="text-p">Live cell screening of high-value targets of interest (e.g. oncogenic mutant)</div>
              </div>
              <div class="innovation-item">
                <div class="img">
                  <img src="@/assets/imgs/img_innovation5.png">

                </div>
                <div class="text-h">Well-Designed Covalent Library Targeting Different Amino Acids</div>
                <div class="text-p">Diverse warheads targeting cysteine, lysine, tyrosine, etc., enable the discovery
                  of small molecule ligands for more proteins in the proteome
                </div>
              </div>
              <div class="innovation-item">
                <div class="img">
                  <img src="@/assets/imgs/img_innovation3.png">

                </div>
                <div class="text-h">Screen in Different Cellular States</div>
                <div class="text-p">Apply different stimuli to cells</div>
              </div>
            </div>
            <div class="innovation-r">
              <div class="innovation-item">
                <div class="img">
                  <img src="@/assets/imgs/img_innovation4.png">

                </div>
                <div class="text-h">Identify Covalent or Non-Covalent
                  Interacting Proteins
                </div>
                <div class="text-p">Greatly increase the application scope of chemoproteomic technology</div>
              </div>
              <div class="innovation-item">
                <div class="img">
                  <img src="@/assets/imgs/img_innovation6.png">

                </div>
                <div class="text-h">Screen in Different Compartments</div>
                <div class="text-p">Maximal proteomic coverage for particular organelles</div>
              </div>
              <div class="innovation-item">
                <div class="img">
                  <img src="@/assets/imgs/img_innovation2.png">

                </div>
                <div class="text-h">Proteome-Wide Screening</div>
                <div class="text-p">Live cell screening of the proteome</div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <collapse-transition>
      <div class="bottom-video fadeInUpLeft" v-if="showVideo">
        <div class="close-right" @click="closeVideo">X</div>
        <video
          id="video"
          controls="controls"
          autoplay="autoplay"
          loop
        >
          <source src="https://bridgene-company-web-upload.s3.us-west-1.amazonaws.com/bridgene-video.mp4" type="video/mp4"/>
        </video>
      </div>
      <div class="video" v-else>
        <div class="play" @click="videoPlay()">
          <img src="@/assets/imgs/icn_play.svg">
        </div>

      </div>
    </collapse-transition>
    <div class="con-con">
      <div class="partnership">

        <ul class="partnership-article">
          <li v-for="article in articles" :key="article.title">
            <div class="title">{{ article.sectionHeader }}</div>
            <div v-if="article.image" class="img-tip">
              <img :src="article.image">
            </div>
            <div class="partnership-con">
              <h1>
                <span v-for="(it, i) in article.title.split('\n')" :key="i" style="display: block">{{ it }}</span>
              </h1>
              <p>{{ article.publishTime }}</p>
              <p>{{ article.content }}</p>
              <p class="blue">
                <a :href="article.link" target="__blank" style="color: #2788f0">
                  {{ article.linkLabel }}
                </a>
              </p>
            </div>
          </li>
        </ul>

        <div class="small-sub-title">Collaboration Models</div>
        <div class="img-sub">
          <img src="@/assets/imgs/img_live cell.png">
        </div>
        <div class="key-title-small">
          <img src="@/assets/imgs/img_IMTAC.png">
        </div>
        <div class="small-sub-title-mb">Discover Drugs For Undruggable Targets</div>
        <div class="small-sub-title-grey">Focused Live Cell Screening Against Targets of Interest</div>
        <div class="ship-list">
          <div class="item">
            Undruggable targets
          </div>
          <div class="item">
            Low-expressing targets
          </div>
          <div class="item">
            Disease-driving mutants: like KRAS G12C
          </div>
        </div>
        <div class="key-title-small">
          <img src="@/assets/imgs/img_IMTAC.png">
        </div>
        <div class="small-sub-title-mb">Deconvolute Phenotypic Screening Hits</div>
        <div class="deconvolute">
          <img src="@/assets/imgs/Deconvolute.png">
        </div>
        <div class="key-title-small">
          <img src="@/assets/imgs/img_IMTAC.png">
        </div>
        <div class="small-sub-title-mb">Screening Of Small Molecule Drugs Or Drug Candidates</div>
        <div class="small-sub-title-mb">Identify the small molecules’ previously unknown targets</div>
        <div class="sub-three">
          <div class="three-item">
            <img src="@/assets/imgs/img_molecule.png" class="img">
            <img src="@/assets/imgs/arrow.svg" class="arrow-bottom">
            <div class="item-number">
              <span class="number">1</span>
            </div>
            <div class="text">
              Small Molecule Drugs or Drug Candidates
            </div>
          </div>
          <div class="three-item">
            <img src="@/assets/imgs/img_live cell-1.png" class="img">
            <img src="@/assets/imgs/arrow.svg" class="arrow-bottom">
            <div class="item-number">
              <span class="number">2</span>
            </div>
            <div class="text">
              Live-Cell<span class="blue">Proteome-Wide</span> Screening
            </div>
            <div class="border-txt">
              Access temporary dynamic pockets in native environment
            </div>

          </div>
          <div class="three-item">
            <img src="@/assets/imgs/three.png" class="long">
            <img src="@/assets/imgs/arrow.svg" class="arrow-bottom">
            <div class="item-number">
              <span class="number">3</span>
            </div>
            <div class="text">
              Quantitative Mass spec Analysis
            </div>
            <div class="border-txt">
              <p>1.Identify which targets bind to the small molecules.
              </p>
              <p>2.Quantify their relative binding affinity.</p>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="con-con" id="Pipline">
      <div class="pipeline">
        <div class="title">PIPELINE</div>
        <div class="pipeline-con">
          <img src="@/assets/imgs/pipeline.png">
          <div class="table-bottom">BridGene is leveraging its proprietary chemoproteomic platform IMTAC™ and
            expertise in covalent chemistry to advance a deep and diversified pipeline focusing on highly
            selective small molecule therapeutics targeting previously undruggable targets for innovative new
            oncology drugs.
          </div>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="con-con" id="News">
      <div class="news">
        <div class="title">NEWS</div>
        <div class="sub_title">
          <div :class="newsTab===0?'sub_item active' :'sub_item'" @click="changeNews(0)">NEWS & EVENTS</div>
          <div :class="newsTab===1?'sub_item active' :'sub_item'" @click="changeNews(1)">MEDIA COVERAGE</div>
        </div>
        <div class="news-con" v-if="newsList && newsList.length>0">
          <div class="news-item"
               @click="api.goToDetail(item)" v-for=" (item , index) in newsList" :key="index">
            <div class="img">
              <img :src="item.headerImage" v-if="item.headerImage">
            </div>
            <div class="text">
              <p>{{item.title}} </p>
            </div>
            <button class="btn" v-if="item.tag">{{item.tag.tagName}}</button>
          </div>
        </div>
        <div class="group-btns">
          <div class="item-button" @click="goNewsMore()">All NEWS & EVENTS</div><br>
          <div class="item-button" @click="goMediaMore()">All MEDIA COVERAGE</div>
        </div>
      </div>
    </div>

    <div class="getintouch" id="Contact">
      <div class="con-con">
        <div class="title">GET IN TOUCH</div>
        <div class="con-box">
          <div class="form">
            <div class="form-item">
              <span class="red">*</span>
              <input type="text" placeholder="Name" v-model="name"/>
            </div>
            <div class="form-item">
              <span class="red">*</span>
              <input type="text" placeholder="Email" v-model="email"/>
            </div>
            <div class="form-item">
              <span class="red">*</span>
              <textarea placeholder="Comment or Message" v-model="message" maxlength="200"/>
            </div>
            <div class="submit" @click="submitFrom">SUBMIT</div>
          </div>
          <div class="form-right-text">
            <div class="right-top">
              <h1>BridGene Biosciences</h1>
              <p>Discover Novel Therapeutic Targets and Quality Leads.</p>
              <p>Accelerate Therapeutic Innovation to Improve Human Health.</p>
              <p class="copy-right">© 2021 BridGene Biosciences</p>
            </div>
            <div class="right-bottom">
              <h1>Contact</h1>
              <ul class="contact-row-container">
                <li v-for="contact in contacts" :key="contact.name" class="contact-row">
                  <p class="contact-row-name">
                    {{ contact.name }}
                  </p>
                  <div class="contact-row-item">
                    <img src="@/assets/imgs/icn_Address.svg" />
                    <p>{{ contact.address }}</p>
                  </div>
                  <div class="contact-row-item">
                    <img src="@/assets/imgs/icn_telephone.svg" />
                    <p>{{ contact.phone }}</p>
                  </div>
                </li>
              </ul>
              
              <div class="item">
                <img src="@/assets/imgs/icn_email.svg" />
                {{contacts.usSite.email}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="96%"

    >
      <div class="dialog-con" v-html="htmlContent">

      </div>

    </el-dialog>
  </div>
</template>
<script>
  import wx from 'weixin-js-sdk'
  // import { mapState, mapGetters } from 'vuex'
  import api from '@/api'
  import { teamMembers, sabMembers } from '@/assets/html/team'

  import CollapseTransition from '@/utils/collapse-transition'
  import { article1, article2, homeBanner } from '@/assets/articles'
  import {constants} from '@/constants'

  export default {
    name: 'home',
    created() {
      this.isWeiXin()
    },
    data() {
      return {
        contacts: constants.contacts,
        articles: [ article1, article2 ],
        isWx: false,
        realScreenUrl: 'https://bridgenebio.s3.us-west-1.amazonaws.com/banner/banner-video-mobile.mp4',
        newsTab: 0,
        aboutTab: 0,
        title: '',
        htmlContent: '',
        dialogVisible: false,
        teamMembers: teamMembers,
        sabMembers: sabMembers,
        showVideo: false,
        name: '',
        email: '',
        message: '',
        newsList: [],
        api: api,
        homeBanner: homeBanner,
      }
    },
    mounted() {
      this.getNewsFour()
      const video = document.getElementById('ckplayer_a1')
      video.play()
      wx.ready(function() {
        document.addEventListener('WeixinJSBridgeReady', function () {
          video.play()
        }, false)
      })
    },
    computed: {},
    methods: {
      isWeiXin() {
        var ua = window.navigator.userAgent.toLowerCase()
        if (/micromessenger/.test(ua)) {
          this.isWx = true
        } else {
          this.isWx = false
        }
      },
      changeAbout(index) {
        this.aboutTab = index
      },
      openDia(title, content) {
        this.title = title
        this.htmlContent = content
        this.dialogVisible = true
      },
      videoPlay() {
        this.showVideo = true
      },
      closeVideo() {
        this.showVideo = false
      },
      hanldeNavList(type) {
        console.log(type)
      },
      changeNews(index) {
        this.newsTab = index
        this.getNewsFour()
      },
      getNewsFour() {
        const categoryInUrl = this.newsTab === 0 ? 'news' : 'media'

        api.getRecentNewsInCategory(categoryInUrl, 4).then(res => {
          if (res.status === 200) {
            this.newsList = res.data || []
          } else {
            this.$message({
              message: res.message,
              type: 'warning'
            })
          }
        }).catch(err => {
          console.log(err)
        })
      },
      goNewsMore() {
        window.open('news/category/news', '_blank')
      },
      goMediaMore() {
        window.open('news/category/media', '_blank')
      },
      submitFrom() {
        if (!this.name) {
          this.$message({
            message: 'Name is required',
            type: 'error'
          })
          return false
        }
        if (!this.email) {
          this.$message({
            message: 'Email is required',
            type: 'error'
          })
          return false
        } else {
          const reg = new RegExp('^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$') // 正则表达式
          if (!reg.test(this.email)) {
            this.$message({
              message: 'Email format is incorrect',
              type: 'error'
            })
            return false
          }
        }
        if (!this.message) {
          this.$message({
            message: 'Message is required',
            type: 'error'
          })
          return false
        }
        const params = {
          name: this.name,
          email: this.email,
          message: this.message
        }
        api.submitMsg(params).then(res => {
          if (res.status === 200) {
            this.$message({
              message: 'Message Sent Successfully.',
              type: 'success'
            })
            this.name = ''
            this.message = ''
            this.email = ''
          } else {
            this.$message({
              message: res.message,
              type: 'warning'
            })
          }
        }).catch(err => {
          console.log(err)
        })
      }
    },
    components: {
      CollapseTransition
    }
  }
</script>
<style lang="scss" scoped>
  ::v-deep .el-dialog {
    border-radius: 10px !important;
  }

  ::v-deep .el-dialog__body {
    padding-top: 10px !important;
  }

  ::v-deep
  .el-dialog__header {

    .el-dialog__title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
      /* identical to box height, or 150% */

      /* Black */

      color: #000000;

    }

    .el-dialog__headerbtn {
      font-size: 28px;

    }
  }

  @media (min-width: 400px) {
    .three-item {
      .img {
        /*width: 320px !important;*/
        /*height: 320px !important;*/
      }

      .long {
        width: 320px !important;
      }

      .text {
        font-size: 16px !important;
      }

      .border-txt {
        width: 320px !important;
        font-size: 16px !important;
      }
    }
  }

  .dialog-con {
    border-top: 1px solid #D7D9DD;;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    word-break: normal;
    /* or 160% */

    /* Black */
    color: #000000;
    padding: 10px;

    ::v-deep p {
      margin: 10px 0;

    }

  }

  .video-banner {
    width: 100vw;

    video,img {
      width: 100%;
    }

    position: relative;

    .video-list {
      position: absolute;
      left: 28px;
      bottom: 124px;
      width: calc(100vw - 48px);

      li {
        list-style-type: none;
        text-align: center;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 27px;
        line-height: 30px;

        /* identical to box height, or 133% */

        /* White */

        color: #FFFFFF;

        margin-bottom: 28px;
        text-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);

      }
    }
  }

  input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    color: #D7D9DD;
  }

  input:-moz-placeholder, textarea:-moz-placeholder {
    color: #D7D9DD;
  }

  input::-moz-placeholder, textarea::-moz-placeholder {
    color: #D7D9DD;
  }

  input:-ms-input-placeholder, textarea:-ms-input-placeholder {
    color: #D7D9DD;
  }

  .home {
    width: 100%;
    margin: 0 auto;
    overflow-x: hidden;
    background: #fff;
  }

  .video-banner {
    width: 100vw;
  }

  .con-con {

    margin: 80px auto;

    .title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
      /* identical to box height, or 133% */

      text-align: center;
      margin-bottom: 20px;
    }

    .key-title-small {
      padding-top: 40px;

      img {
        height: 30px;
      }
    }

    .small-sub-title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */

      text-align: center;

      /* Black */

      color: #000000;

      margin: 40px 0;

    }

    .small-sub-title-mb {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */

      /* Black */

      color: #000000;
      margin: 16px 0;
    }

    .small-sub-title-grey {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height, or 160% */

      /* Gray Blue */

      color: #5F6F8F;
    }

    /*about us*/
    .about-wrap {
      width: 100%;

      .sub_title {
        margin-bottom: 44px;
        display: flex;
        justify-content: center;
        align-items: center;

        .sub_item {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          /* identical to box height, or 150% */
          text-align: center;
          text-transform: uppercase;
          margin: 0 12px;

          /* Black */

          color: #000000;

        }

        .active {
          border-bottom: 2px solid #2788F0;
        }
      }

      .about-sub-title {
        padding: 10px 0;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 36px;
        /* identical to box height, or 150% */

        text-align: center;

        /* Black */

        color: #000000;

      }

      .about-con {
        padding: 0 16px;

        p {
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .about-list {
        padding: 0 16px;
        margin: 0 auto;
        max-width: 420px;

        .about-item {
          border: 1px solid #D7D9DD;
          border-radius: 8px;
          overflow: hidden;
          margin-bottom: 16px;
          padding-bottom: 20px;

          .img {
            width: 100%;
            margin-bottom: 20px;
            overflow: hidden;
            display: flex;
            justify-content: center;

            img {
              max-width: 100%;
              max-height: 262px;
              width: 100%;
              height: auto;
            }
          }

          .text {
            padding: 0 20px;
            height: 24px;
            overflow: hidden;

            p {
              font-family: Montserrat;
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: #000;
              word-break: break-word;
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
            }
          }

          .job {
            padding: 0 20px;
            height: 24px;
            overflow: hidden;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #5F6F8F;
          }

        }

        .news-item:nth-child(4n) {
          margin-right: 0;
        }

      }

    }

    /*PLATFORM*/
    .planform {
      .planform-con {
        padding: 0 16px;

        p {
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: #000;

          &:last-child {
            padding-bottom: 40px;
            border-bottom: 1px solid #D7D9DD;
          }
        }
      }

      .planform-con-key {
        .key-title {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 30px;
          margin-bottom: 40px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
          /* identical to box height, or 150% */

          text-align: center;

          /* Black */

          color: #000000;

          padding: 40px 0;

          img {
            margin-right: 16px;
            width: 98px;
            height: 30px;
          }

        }

        .gif-con {
          height: 508px;
          border-bottom: 1px solid #D7D9DD;
          margin: 0 16px;

          .gif-item {
            width: 50%;
            text-align: center;
            float: left;
            height: 214px;
            margin-bottom: 40px;

            img {
              width: 128px;
              height: 128px;
              margin-bottom: 16px;
            }

            p {
              font-family: Montserrat;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              /* or 143% */

              text-align: center;

              /* Black */

              color: #000000;

            }
          }
        }

        .con-key-list {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-bottom: 80px;
          border-bottom: 1px solid #D7D9DD;

          .con-key-item {
            width: 256px;
            margin: 0 32px;

            .img {
              width: 256px;
              height: 256px;
            }

            .text {
              font-family: Montserrat;
              font-style: normal;
              font-weight: 600;
              font-size: 20px;
              line-height: 32px;
              text-align: center;
              color: #000000;
            }
          }

        }

        .innovation-con {
          padding: 0 5vw;

          .innovation-l, .innovation-r {
            width: 50%;
            float: left;

            .innovation-item {
              width: calc(100% - 32px);
              margin: 0 16px;
              float: left;
              display: flex;
              flex-wrap: wrap;
              margin-bottom: 40px;

              .img {
                height: 60px;
                margin-bottom: 12px;
                width: 100%;

                img {
                  height: 60px;
                }
              }

              .text-h {
                width: 100%;
                font-family: Montserrat;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                /* or 143% */

                /* Black */

                color: #000000;
                margin-bottom: 12px;

              }

              .text-p {
                /* Description */

                font-family: Montserrat;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 16px;
                /* or 133% */

                /* Gray Blue */

                color: #5F6F8F;

              }
            }

          }

        }
      }
    }

    /*PARTNERSHIP*/
    .partnership {
      padding: 0 16px;

      .partnership-article {
        display: flex;
        flex-direction: column;
        gap: 4rem;
      }

      .img-tip {
        display: flex;
        height: 80px;
        justify-content: center;
        align-items: center;
        margin: 24px auto;

        img {
          height: 80px;
        }
      }

      .partnership-con {
        padding-bottom: 40px;
        border-bottom: 1px solid #D7D9DD;

        h1 {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #000;
          margin-bottom: 20px;
        }

        p {
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: #000;
          margin-bottom: 20px;
        }

        p.blue {
          color: #2788F0;
        }
      }

      .deconvolute {
        margin-top: 40px;
      }

      .img-sub, .deconvolute {
        max-width: 360px;
        margin: 0 auto;

        img {
          width: 100%;
        }
      }

      .sub-three {

        .three-item {
          display: flex;
          justify-content: center;
          flex-direction: column;
          flex-wrap: wrap;
          align-items: center;
          margin-bottom: 40px;

          .img {
            width: 160px;
            height: 160px;
            margin-bottom: 8px;
            display: block;
          }

          .arrow-bottom {
            width: 160px;
            display: block;
            margin-bottom: 8px;
          }

          .item-number {
            height: 24px;
            margin-bottom: 8px;

            .number {
              font-family: Montserrat;
              font-style: normal;
              font-weight: bold;
              font-size: 14px;
              line-height: 24px;
              /* identical to box height, or 171% */

              text-align: center;
              text-transform: capitalize;

              /* New Brand */

              color: #2788F0;
              background: #F6F8FA;
              /* Line */

              border: 1px solid #D7D9DD;
              width: 24px;
              height: 24px;
              line-height: 24px;
              display: inline-block;
              border-radius: 50%;

            }
          }

          .text {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            /* or 143% */

            text-align: center;

            /* Black */

            color: #000000;

            .blue {
              color: #2788F0;
            }
          }

          .border-txt {
            margin-top: 10px;
            width: 275px;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #5F6F8F;
            padding: 10px;
            /* Yellow */

            border: 1px solid #FFC32D;

            border-radius: 16px;

          }

          .long {
            width: 275px;
          }
        }

      }

      .ship-list {
        width: 100%;
        border-bottom: 1px solid #D7D9DD;
        height: 120px;
        margin-top: 24px;

        .item {
          float: left;
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          /* identical to box height, or 133% */

          /* New Brand */

          color: #2788F0;
          padding: 8px 12px;
          border: 1px solid #2788F0;
          box-sizing: border-box;
          border-radius: 8px;
          margin-bottom: 8px;
          margin-right: 8px;
        }

      }
    }

    .pipeline {
      .pipeline-con {
        margin: 0 auto;

        img {
          width: 100%;
        }

        .table-bottom {
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          padding: 20px 16px;
          text-align: left;

        }

      }
    }

    .news {
      .sub_title {
        margin-bottom: 44px;
        display: flex;
        justify-content: center;
        align-items: center;

        .sub_item {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          /* identical to box height, or 150% */
          text-align: center;
          text-transform: uppercase;
          margin: 0 12px;

          /* Black */

          color: #000000;

        }

        .active {
          border-bottom: 2px solid #2788F0;
        }
      }

      .news-con {
        padding: 0 16px;
        max-width: 360px;
        margin: 0 auto;

        .news-item {
          border: 1px solid #D7D9DD;
          border-radius: 8px;
          overflow: hidden;
          margin-bottom: 16px;

          .img {
            width: 100%;
            max-height: 262px;
            height: auto;
            margin-bottom: 20px;
            overflow: hidden;
            display: flex;
            justify-content: center;

            img {
              max-width: 100%;
              max-height: 262px;
              width: 100%;
              height: auto;
            }
          }

          .text {
            padding: 0 20px;
            height: 72px;
            overflow: hidden;

            p {
              font-family: Montserrat;
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: #000;
              word-break: break-word;
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }
          }

          .btn {

            padding: 4px 12px;
            background: #FFFFFF;
            /* Line */
            border: 1px solid #D7D9DD;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #2788F0;
            border-radius: 4px;
            margin-left: 20px;
            margin-top: 20px;
            margin-bottom: 16px;

          }

        }

        .news-item:nth-child(4n) {
          margin-right: 0;
        }

      }

      .group-btns {
        display: table;
        justify-content: center;
        align-items: center;
        margin: 40px auto 20px auto;

        .item-button {
          height: 44px;
          line-height: 44px;
          background: #2788F0;
          border-radius: 8px;
          margin: 5px auto;
          text-align: center;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          text-transform: uppercase;
          padding: 0 20px;
          /* white */
          color: #FFFFFF;
          cursor: pointer;
        }
      }

      .news-btn-more {
        width: 144px;
        height: 44px;
        line-height: 44px;
        background: #2788F0;
        border-radius: 8px;
        margin: 40px auto;
        text-align: center;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        text-transform: uppercase;
        /* white */
        color: #FFFFFF;
      }

    }
  }

  .line {
    height: 4px;
    width: 100%;
    background: #FFC32D;
  }

  .video {
    width: 100%;
    height: 210px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url("../../assets/imgs/video-bg.png") no-repeat center center;
    margin-top: 160px;
    background-size: 100% auto;

    .play {
      width: 64px;
      height: 64px;
      cursor: pointer;
    }
  }

  .bottom-video {
    width: 100%;
    height: 210px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url("../../assets/imgs/video-bg.png") no-repeat center center;
    margin-top: 160px;
    background-size: 100% auto;
    position: relative;

    .close-right {
      position: absolute;
      top: 10px;
      right: 10px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 40px;
      cursor: pointer;
      z-index: 10;
    }

    video {
      width: 100%;
      height: 210px;
    }
  }

  .getintouch {
    padding: 80px 16px;
    background: #F6F8FA;

    .con-con {
      margin: 0 !important;
    }

    .con-box {

      .form {
        width: 100%;
        margin-bottom: 40px;

        .form-item {
          border: 1px solid #D7D9DD;
          padding: 12px;
          display: flex;
          margin-bottom: 12px;
          border-radius: 8px;
          background: #fff;

          span.red {
            color: #ff0000;
            margin-right: 5px;
          }

          input, textarea {
            border: none;
            background: transparent;
            width: 400px;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
          }

          textarea {
            height: 180px;
          }
        }

        .submit {
          border-radius: 8px;
          background: #2788F0;
          height: 48px;
          width: 100%;
          color: #fff;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 48px;
          text-align: center;
        }
      }

      .form-right-text {
        .right-top {
          margin-bottom: 40px;
        }

        .right-bottom {

          .item {
            margin-top: 20px;
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            height: 16px;
            color: #000000;
            align-items: center;
            display: flex;
          }
        }

        h1 {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #000000;
          margin-bottom: 12px;
        }

        p {
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;

        }

        .copy-right {
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          margin-top: 12px;
        }
      }
    }
  }

  @-webkit-keyframes load {
    0% {
      width: 0%;
    }
    100% {
      width: 100%;
    }

  }


.contact-row-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contact-row {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.contact-row-name {
  font-weight: 600 !important;
  font-size: 0.8rem !important;
  line-height: 1.5rem;
}

.contact-row-item {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}
</style>
